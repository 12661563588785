import { createStore } from 'vuex';
import MessagesEng from "../scripts/MessagesEng";
import Config from "../scripts/Config";
import Structures from "../classes/Structures";
import Backend from '../classes/backend.js';
import Utils from "../scripts/Utils";
import * as Constants from "@/classes/Constants";
import Achievements from "@/classes/Achievements";
import LuckyConfig from "@/vue/lucky/LuckyConfig";
//import Wallet from "../../src_pay/src/payments/wallet";
import { ref, shallowRef } from 'vue';
import GameList from "@/scripts/GameList";
import {priceFraction} from "@/classes/Constants";
const defaultChain = 'WAX';
const wallet = shallowRef(null);
//wallet.value = new Wallet(defaultChain);
const renderTrigger = ref(0);

/* */

export const store = createStore({
    state: {
        gameStarted: false,         //if true, then blocksin game started, not redirect to desktop page if orientation changed
        realWidth: 1080,            //Реальный размер области pixi
        realHeight: 1920,
        deviceWidth: 1080,            //Device size (window.innerWidth)
        deviceHeight: 1920,
        orient:'V',
        ss:1,
        router:null,
        routeQuery:'{}',            //string of route.query. Default '{}'

        bets:Structures.bets,
        betsListCurrent:[],         //array with currently selected 'bets type' and 'game type'
        betsAddDeltaTime:1000,      //Max time for transition animation in recent bets list
        blockspinState:{},      //Blockspin state from Backend.getState()
        achievObj:Achievements,
        achievSlotGames:{},         //Active slot games (isSoon!==true, testing!==true)
        boostsObj:Constants.boost,
        firstUserAction:false,          //Indicate that user make first action for continue loading "slots" images in start page
        gameType:'allGames',                //Active game type in PageOur (slots (it allGames), scratchCards, crashGames, instantGames, spincity, slotbattle)
        gameTypesNew:[],
        gameTypesSoon:[],
        gameList:[[]],                    //List of current gameType with game icons {name,img}

        isBuyChipsPopupOpen:false,      //ДОРАБОТАТЬ
        isShopPopupOpen:false,      //ДОРАБОТАТЬ
        luckyConfig:LuckyConfig,
        leaderboard:Structures.leaderboard,
        loginData:null,
        messages:MessagesEng,   //Game messages
        notificationsFilter:'Unread',
        pageCurrent:0,          //Visible page, 0 - Our Games
        pageVisibleUserInfo:false,
        popupConfirm:{visible:false,msg:'',title:'',actionOk:null,actionCancel:null},
        popupMessage:{visible:false,msg:'',title:'',error:false},      //popup message window
        popupVisible:{login:false,signup:false,terms:false,privacy:false,userAccount:false,tickets:false,prizesClaim:false,
            notifications:false,chipsClaim:false,referralInfo:false,redeemPopup:false,learnChips:false,learnNft:false,
            achievStats:false,learnNewAccount:false,connectTelegram:false,popupFirst:false,popupAirdrop:false,rewards:false,},         //Popups visible
        popupQuickHelp:{dailyStreak:false,gemsInfo:false,referralCopy:false,redeemPopup:false,prizesClaimQuick:false,
            popupMessage:false,userAccountId:false,userInfoGemsInfo:false,popupShop:false},         //Popups "quick help" visible
        popupVip:{visible:false,vipType:'bronze'},           //{bronze/silver/gold}
        popupWalletType:{visible:false,buyObj:null,currency:'wax'},        //Popup for select wallet subtype wax/anchor for wax and Phantom/Solflare for sol
                                                            //buyObj:{itemType,itemKey,currency:{wax,sol,ronin,usd},amount,price,walletType:{wax,anchor,phantom,solflare}}
        popupBuyShopConfirm:{visible:false,buyObj:null},    //Final state of buying if wallet login OK. If confirm send buy command to backend and make purchase
        popupOpenBot:{visible:false,buyObj:null},
        popupLuckyWheel:{visible:false,},    //Popup for LuckyWheel
        popupLevelPopup:false,
        popupNoWallet:{visible:false,walletType:'wax'},      //If no wallet found, then show popup with create wallet link. walletType: wax/anchor/phantom/solflare/ronin
        popupRewardPopup:{visible:false,rewardsCompleted:{}},     //rewardsCompleted:{'id':{}}
        popupUnlockConfirm:{visible:false,gameObj:null},    //game obj from GameList.js
        privacy:{p:null,li:null,h2:null,h3:null},           //Privacy Policy
        prizes:{visible:false,       //raffle Prizes sate
            period:'daily',claimType:'unclaimed',  //{daily/weekly/monthly} {nft/digital}, {claimed/unclaimed}
            content:'prizes',blockchain:'',               //{prizes/winners}, blockchain:{''=>(it's all prizes)/digital/wax/ronin/sol/bnb/matic}
        },
        prizePoolBets:{prizePoolSelect:'current',prizePoolShowToken:true,    //State of leaderboard prizepool bets.
            prizePoolActive:'hourly',resetInValue:'',   // {prizePoolSelect:current/previous (it's previous), prizePoolActive: hourly/daily/weekly, prizePoolShowToken: true/false}
        },
        prizePoolGames:{prizePoolSelect:'current',prizePoolShowToken:true,    //State of leaderboard prizepool games.
            prizePoolActive:'games',resetInValue:'',   // {prizePoolSelect:current/previous (it's previous), prizePoolActive: games/myProgress, prizePoolShowToken: true/false}
        },
        rewards:{tasks:{},daily:{},social:{}},          //player rewards
        rewardCurrent:'tasks',              //tasks/daily/social
        recentBets:{    //State of RecentBets.
            betsActive:'all',   // { prizePoolActive: all/big/mine,}
        },
        shop:{visible:false,shopType:'chips',currency:'wax',     // {chips/boost/vip} {wax/sol/ronin/usd}
            items:{chips:Constants.chips,boost:Constants.boost,vip:Constants.monthlyCards},
        },
        sound:true,             //sound in all games

        terms:{p:null,li:null,h2:null,h3:null},           //Terms and Conditions
        ticketsBuyCount:{bronze:0, silver:0, gold:0, lottoBronze:0, lottoSilver:0, lottoGold:0, buyVisible:false, ticketType:'',buyCount:0,buyPrise:0},

        tgInitData:null,
        tgMode:false,           //if true, then website running under Telegram
        userId:null,
        userName:null,
        userData:null,          //Full user data, messages, chips, etc. See in Main.vue=>loginSetUserData
        userLevel:null,
        unlockedSlots:[],
        discordUsername:"",
        numInvited: 0,
        referralCode: "",
        wallet,            //Blockchain wallets
        pushNotifToken: null,
        walletBalance:0,
        walletUserName:"",

        gameBetsAmount(game){
            //return 15000
            try {
                if (game) {
                    return this.userData.numBets[game] ?
                        (this.userData.numBets[game].numWins?this.userData.numBets[game].numWins:0) +
                        (this.userData.numBets[game].numLosses?this.userData.numBets[game].numLosses:0) : 0;
                } else {
                    let total = 0
                    for (const gameName in this.userData.numBets) {
                        // Slot games only
                        if (GameList.allGames.hasOwnProperty(gameName)) {
                            total += this.gameBetsAmount(gameName);
                        }
                    }
                    return total
                }
            }catch (e) {

            }
            return 0;
        },
        getDateWithTime:function(timestamp) {
            let date = new Date(timestamp);
            const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
            return months[date.getMonth()] + " " + (date.getDate()) + ", " + (date.getFullYear()) + " " + (date.getHours().toString().padStart(2, "0")) +
                ":" + (date.getMinutes().toString().padStart(2, "0")) + ":" + (date.getSeconds().toString().padStart(2, "0"));
        },
        formatIcon(icon) {
            //debugger
            let s=(icon && typeof(icon)==='string')?icon:'https://cdn.discordapp.com/embed/avatars/0.png';
            let ext = s.substring(s.length - 4);
            if (ext !== '.png' && ext !== '.jpg' && ext !== 'webp') {
                s = s + '.png';
            }
            return s;
        },
        showMessagePopup:function(msg='',title='',error=false){
            this.popupMessage.title = title;
            this.popupMessage.msg = msg;
            this.popupMessage.error = error;
            this.popupMessage.visible = true;
        },
        updateUserLevel:function(){
            try {
                let xpForCurrentLevel = 1000;
                let xpPerLevelIncrease = 1000;

                for (let i = 0; i < 100; i++) {
                    if (this.userData.experience < xpForCurrentLevel) {
                        this.userLevel=i;
                        MyLog('updateUserLevel: '+this.userLevel);
                        return this.userLevel;
                    }
                    xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1 + this.blockspinState.xpPerLevelIncreasePercentage / 100));
                    xpForCurrentLevel += xpPerLevelIncrease;
                }
                this.userLevel=100;
                return this.userLevel;
            }catch (e) {}
            this.userLevel=0;
            MyLog('updateUserLevel: '+this.userLevel);
            return this.userLevel;
        }
    },
    actions:{
        async buyTickets({ commit, state,  getters}, buyTicketsData) {
            const purchase = await Backend.buyTickets(
                buyTicketsData.ticketType,
                buyTicketsData.quantity,
                buyTicketsData.totalPrice,
                getters.getLoginData
            );
            if(purchase.success) {
                switch(buyTicketsData.ticketType) {
                    case "bronze": {
                        state.userData.bronzeTickets+=buyTicketsData.quantity;
                        break;
                    }
                    case "silver": {
                        state.userData.silverTickets+=buyTicketsData.quantity;
                        break;
                    }
                    case "gold": {
                        state.userData.goldTickets+=buyTicketsData.quantity;
                        break;
                    }
                }
                try{
                    state.userData.gems-=buyTicketsData.totalPrice;
                }catch (e) {}
            }
            return purchase;
        },
        /*
        else {

                }
        * */
        async clickCurrency({ state, dispatch },currency){
            try{
                if (state.shop.currency===currency)
                    return;

                state.shop.currency=currency;
                state.walletUserName='';
                state.walletBalance=0;
                if (state.shop.currency!=='usd') {
                    const curWallet = state.wallet[state.shop.currency];
                    const walletUserName = await curWallet.getUsername();
                    if (walletUserName) {
                        state.walletUserName = walletUserName;
                        state.walletBalance = (await curWallet.getWalletBalance()).toFixed(Constants.priceFraction[state.shop.currency]);
                    }
                }
            }
            catch (e) {}
        },
        //Click wallet button in shop. Actions connect wallet/disconnect wallet
        async clickWallet({ state, dispatch }){
            try{
                if (!state.wallet) {
                    state.showMessagePopup(state.messages.m032_07,state.messages.m029_5,true);
                    return;
                }
                const currency=state.shop.currency;
                if (currency==='xtr'){
                    return;
                }

                let curWallet,walletUserName;
                if (currency!=='usd') {
                    curWallet = state.wallet[state.shop.currency];
                    renderTrigger.value++;
                    walletUserName = await curWallet.getUsername();
                }

                if (!walletUserName){           //Action if wallet not connected
                    if (currency === 'wax' || currency === 'sol' || (currency === 'bnb') || (currency === 'pol') || (currency === 'usd')){
                        state.popupWalletType.currency = currency;
                        state.popupWalletType.buyObj = null;
                        state.popupWalletType.visible = true;
                        return;
                    }
                    if ((currency === 'ton')){
                        const obj={currency:state.shop.currency}
                        dispatch('loginWallet',obj);
                    }
                }
                else{           //Show disconnect wallet popup
                    //{visible:false,msg:'',title:'',actionOk:null,actionCancel:null},
                    state.popupConfirm.title=state.messages.m028_2;
                    state.popupConfirm.msg=state.messages.m_disconnect;
                    state.popupConfirm.actionOk=async ()=>{
                        state.walletUserName='';
                        state.walletBalance=0;
                        await curWallet.logout();
                    };
                    state.popupConfirm.visible=true;
                }
            }catch (e) {
                const msg='store.clickWallet catch. Message: '+e.message+'\nstack: '+e.stack;
                state.showMessagePopup(msg,state.messages.m032_08,true);
                console.log(msg);
            }
        },
        // obj={currency,walletType,buyObj} login to selected wallet
        // if buyObj not null then call clickBuy for continue purchase
        async loginWallet({ state, dispatch },obj){
            let ret={success:false};
            try {
                const curWallet = state.wallet[obj.currency];
                if (obj.currency === 'wax') {
                    renderTrigger.value++;
                    await curWallet.setChain("WAX");
                }
                if (obj.currency === 'sol') {
                    renderTrigger.value++;
                    await curWallet.setChain("SOL");
                }
                if (obj.currency === 'pol') {
                    renderTrigger.value++;
                    await curWallet.setChain("POL");
                }
                if (obj.currency === 'bnb') {
                    renderTrigger.value++;
                    await curWallet.setChain("BNB");
                }
                renderTrigger.value++;
                ret = await curWallet.login(obj.walletType);
                if (ret.success === false) {
                    let txt;
                    txt = ret.msg === 'wax undefined' ? 'wax' : txt;
                    txt = ret.msg === 'anchor undefined' ? 'anchor' : txt;
                    txt = ret.msg === 'phantom undefined' ? 'phantom' : txt;
                    txt = ret.msg === 'solflare undefined' ? 'solflare' : txt;
                    if (txt) {
                        state.popupNoWallet.walletType = txt;
                        state.popupNoWallet.visible = true;
                    } else {
                        txt = 'store.clickBuy wax/sol connect: ' + ret.msg;
                        state.showMessagePopup(txt, state.messages.m029_5, true);
                    }
                    return ret;
                }

                state.walletUserName='';
                state.walletBalance=0;
                if (ret.success===true){
                    const curWallet = state.wallet[state.shop.currency];
                    state.walletUserName = await curWallet.getUsername();
                    state.walletBalance = (await curWallet.getWalletBalance()).toFixed(Constants.priceFraction[state.shop.currency]);
                    if (obj.buyObj){
                        dispatch('clickBuy',obj.buyObj)
                    }
                }
            }
            catch (e) {

            }
            return ret;
        },
                //Buy item in the Game shop, check for wallet connected, if not connected then show 'select wallet' popup
                // if OK show 'Confirm buying popup'
        async clickBuy({ state, dispatch },obj){        //{itemType,itemKey,currency,amount,price,walletType}  //price for 1 item
            try {                               //walletType for currency wax: wax/anchor, walletType for currency sol: phantom/solflare,
                //state.showMessagePopup('1','2',true)
                if (!state.wallet) {
                    state.showMessagePopup(state.messages.m032_07,state.messages.m029_5,true);
                    return;
                }
                obj.totalPrice=Utils.roundToPlaces(obj.amount*obj.price,priceFraction[obj.currency]);
                if (obj.currency === 'wax' || obj.currency === 'sol' || obj.currency === 'ronin' || obj.currency === 'bnb' || obj.currency === 'pol'
                    || obj.currency === 'ton') {
                    const curWallet = state.wallet[obj.currency];
                    renderTrigger.value++;
                    let walletUserName = await curWallet.getUsername();

                    //if wallet not login and currency wax or sol then shop select wallet subtype popup and return
                    if (!walletUserName && (obj.currency === 'wax' || obj.currency === 'sol' || obj.currency === 'bnb' || obj.currency === 'pol')){
                        if (!obj.walletType) {      //if no walletType then show popup for select walletType
                            state.popupWalletType.currency = obj.currency;
                            state.popupWalletType.buyObj = obj;
                            state.popupWalletType.visible = true;
                            return;
                        }
                    }
                    //if wallet not login and currency ronin then shop select wallet subtype popup and return
                    if (!walletUserName && (obj.currency === 'ronin')){
                        renderTrigger.value++;
                        await curWallet.setChain("RON");
                        renderTrigger.value++;
                        let ret=await curWallet.login(obj.currency);
                        if (ret.success===false){
                            if (ret.msg==='ronin undefined'){        //if ronin wallet not installed then show message
                                state.popupNoWallet.walletType = 'ronin';
                                state.popupNoWallet.visible = true;
                            }
                            else
                                state.showMessagePopup('store.clickBuy ronin connect: '+ret.msg,state.messages.m029_5,true);
                            return;
                        }
                        renderTrigger.value++;
                        walletUserName = await curWallet.getUsername();
                    }

                    if (!walletUserName && (obj.currency === 'ton')){
                        const logObj={currency:state.shop.currency,buyObj:JSON.parse(JSON.stringify(obj))}
                        dispatch('loginWallet',logObj);
                    }

                    //Wallet connected, continue buying
                    if (walletUserName){
                        state.popupBuyShopConfirm.buyObj=JSON.parse(JSON.stringify(obj));
                        state.popupBuyShopConfirm.visible=true;
                    }
                }
                if (obj.currency === 'usd'){
                    state.showMessagePopup(state.messages.m035_09,state.messages.m029_5,true);
                }
                if (obj.currency === 'xtr'){
                    state.popupBuyShopConfirm.buyObj=JSON.parse(JSON.stringify(obj));
                    state.popupBuyShopConfirm.visible=true;
                }
            }
            catch (e) {
                state.showMessagePopup('store.clickBuy catch. Message: '+e.message+'\nstack: '+e.stack,state.messages.m032_08,true);
                console.log('store.clickBuy catch: ',e.message,'\nstack: ',e.stack)
            }

        },
        //Final step of buying items in shop. Get Item ID in backend and make purchase on selected wallet
        async buyFinish({state},obj){
            //obj:{itemType:{chips/boost/vip},itemKey,currency:{wax,sol,ronin,usd},amount,price,walletType:{wax,anchor,phantom,solflare}}
            try {
                const curWallet = state.wallet[obj.currency];
                //const curWallet = state.wallet[obj.currency];
                const walletUserName=await curWallet.getUsername();
                let purchase=null;
                if (obj.itemType==='chips') {           //Get purchase ID from backend for CHIPS
                    purchase = await Backend.buyChips(
                        obj.itemKey,
                        obj.amount,
                        obj.totalPrice,
                        curWallet.chain,
                        walletUserName,
                        state.loginData
                    );
                }
                if (obj.itemType==='boost') {           //Get purchase ID from backend for BOOST
                    purchase = await Backend.buyBoost(
                        obj.itemKey,
                        obj.amount,
                        obj.totalPrice,
                        false,
                        curWallet.chain,
                        walletUserName,
                        state.loginData
                    );
                }
                if (obj.itemType==='vip') {           //Get purchase ID from backend for VIP
                    purchase = await Backend.buyMonthlyCard(
                        obj.itemKey,
                        obj.price,
                        curWallet.chain,
                        walletUserName,
                        state.loginData
                    );
                }
                let tx=null;
                if (purchase.purchaseId) {      //Make purchase on selected blockchain wallet
                    renderTrigger.value++;
                    const payObj = await wallet.value.makePurchase(obj.currency,purchase.purchaseId, obj.amount*obj.price);

                    if (payObj.success) {
                        tx=payObj.txId;
                        setTimeout(function () {
                            let s='';
                            s=obj.itemType==='chips'?'chips':s;
                            s=obj.itemType==='boost'?'boosts':s;            //'s' for getUser
                            s=obj.itemType==='vip'?'monthlyCard':s;
                            Utils.getUser(state.loginData,s);
                        }, 2000);

                    }
                    else{
                        state.showMessagePopup(payObj.msg,state.messages.m029_5,true);
                    }
                }
                else {
                    if (purchase.error)
                        state.showMessagePopup(purchase.error,state.messages.m029_5,true);
                    await this.dispatch("getState");
                }

                if (tx) {
                    if (obj.itemType==='chips') {           //Popup for CHIPS result successful
                        const chipsAmount = state.blockspinState.chipsAmount;
                        const totalChips = obj.amount * chipsAmount[obj.itemKey];
                        state.showMessagePopup(state.messages.m035_01 + " " + totalChips + " " + state.messages.m035_02, state.messages.m035_03);
                    }
                    if (obj.itemType==='boost') {           //Popup for BOOST result successful
                        let s=obj.amount>1?state.messages.m035_06:state.messages.m035_05;
                        state.showMessagePopup(s, state.messages.m035_03);
                    }
                    if (obj.itemType==='vip') {           //Popup for monthlyCard result successful
                        let s=state.messages.m035_07+' ' + obj.itemType + ' ' +state.messages.m035_08;
                        state.showMessagePopup(s, state.messages.m035_03);
                    }
                }
            }
            catch (e) {
                state.showMessagePopup('store.buyFinish catch. Message: '+e.message+'\nstack: '+e.stack,state.messages.m032_08,true);
                console.log('store.buyFinish catch: ',e.message,'\nstack: ',e.stack)
            }
        },
        async buyFinishTelegram({state},obj) {
            //obj:{itemType:{chips/boost/vip},itemKey,currency:{wax,sol,ronin,usd},amount,price,walletType:{wax,anchor,phantom,solflare}}
            try {
                //const curWallet = state.wallet[obj.currency];
                let purchase = null;
                obj.itemType=obj.itemType==='vip'?'monthlyCard':obj.itemType;
                obj.quantity=obj.amount;
                //obj.totalPrice=obj.price * obj.amount;            //already here
                obj.tgInitData=state.tgInitData;
                purchase = await Backend.buyItemTelegram(obj);

                if (purchase) {
                    if (purchase.success===true){
                        const tg = window.Telegram.WebApp;
                        MyLog('try open invoice URL: '+purchase.data.result);
                        tg.openInvoice(purchase.data.result,Utils.invoiceClosed);
                        //state.popupOpenBot.visible=true;
                    }
                    else{
                        state.showMessagePopup(purchase.error,state.messages.m029_5,true);
                    }
                    MyLog(JSON.stringify(purchase))
                }
            }
            catch (e) {
                state.showMessagePopup('store.buyFinishTelegram catch. Message: '+e.message+'\nstack: '+e.stack,state.messages.m032_08,true);
                console.log('store.buyFinishTelegram catch: ',e.message,'\nstack: ',e.stack)
            }
        },
        async claimChips({ state }) {
            const claim = await Backend.claimChips(state.loginData);
            if(claim.numChips) {
                state.userData.chips+=claim.numChips;
                state.userData.claimedFreeChipsToday=true;
            }
            return claim;
        },
        claimDigitalPrize({ state },prizes) {
            state.showMessagePopup(state.messages.m038_01,state.messages.m038_05);
        },
        async claimNfts({ commit, getters, dispatch}, claimNftsData) {
            const claim = await Backend.claimNfts(
                claimNftsData.chain,
                claimNftsData.address,
                getters.getLoginData
            );
            if(!claim.error) {
                dispatch('getState');
                dispatch('getUser');
            }
            return claim;
        },
        async claimNotification({ commit, getters, dispatch, state }, notification){
            const claim = await Backend.claimNotification(state.loginData, notification._id);
            let msg='';
            if(claim.success===true) {
                switch(notification.prizeType) {
                    case "chips": {
                        state.userData.chips+=notification.prizeAmount?notification.prizeAmount:0;
                        break;
                    }
                    case "gems": {
                        state.userData.gems+=notification.prizeAmount?notification.prizeAmount:0;
                        break;
                    }
                    case "bronzeTickets": {
                        state.userData.bronzeTickets+=notification.prizeAmount?notification.prizeAmount:0;
                        break;
                    }
                    case "silverTickets": {
                        state.userData.silverTickets+=notification.prizeAmount?notification.prizeAmount:0;
                        break;
                    }
                    case "goldTickets": {
                        state.userData.goldTickets+=notification.prizeAmount?notification.prizeAmount:0;
                        break;
                    }
                    case "monthlyCard": {
                        await dispatch("getUser",'monthlyCard');
                        break;
                    }
                }
                msg=state.messages.m029_6+' '+Utils.getClaimText(notification);
                state.showMessagePopup(msg,state.messages.m035_03,false);
                notification.isClaimed=true;
            }
            else{
                state.showMessagePopup(claim.error,state.messages.m032_08,true);
            }
        },
        clickGetTickets({ state, getters}) {
            if (getters.isGuest){
                state.popupVisible.learnNewAccount=true;
            }
            else{
                state.popupVisible.tickets=true;
            }
        },
        async enterRaffle({ commit, state, getters}, enterRaffleData) {
            const entry = await Backend.enterRaffle(
                enterRaffleData.raffleType,
                enterRaffleData.quantity,
                getters.getLoginData
            );
            if(entry.success) {
                switch(enterRaffleData.raffleType) {
                    case "daily": {
                        state.userData.bronzeTickets-=enterRaffleData.quantity;
                        break;
                    }
                    case "weekly": {
                        state.userData.silverTickets-=enterRaffleData.quantity;
                        break;
                    }
                    case "monthly": {
                        state.userData.goldTickets-=enterRaffleData.quantity;
                        break;
                    }
                }
                try{
                    state.userData.numEntries[enterRaffleData.raffleType]+=enterRaffleData.quantity;
                }catch (e) {}

            }
            return entry;
        },
        getOtherGamesAmount({state, getters},otherAchievements) {
            otherAchievements.forEach((achievement)=>{
                try {
                    achievement['total'] = 1;
                    achievement['amount'] = 0;
                    if (getters.achievementsStatsUnlocked) {
                        switch (achievement.name) {
                            case "trophy": {
                                achievement['total'] = 1;
                                achievement['amount'] = 0;
                                break
                            }
                            case "chad": {
                                achievement['total'] = Object.keys(state.achievObj).length + Object.keys(state.achievSlotGames).length;
                                achievement['amount'] = Object.keys(state.userData.achievements).length;
                                break
                            }
                            case "trainwreck": {
                                achievement['total'] = 10000;
                                achievement['amount'] = getters.TotalLossAmount < 10000 ? getters.TotalLossAmount : 10000;
                                break
                            }
                            case "slotsohollic": {
                                achievement['total'] = 10000;
                                achievement['amount'] = state.gameBetsAmount() < 10000 ? state.gameBetsAmount() : 10000;
                                break
                            }
                            case "addict": {
                                achievement['total'] = 50000;
                                achievement['amount'] = state.gameBetsAmount() < 50000 ? state.gameBetsAmount() : 50000;
                                break
                            }
                            case "tycoon": {
                                achievement['total'] = 100000;
                                achievement['amount'] = state.gameBetsAmount() < 100000 ? state.gameBetsAmount() : 100000;
                                break
                            }
                            case "master": {
                                achievement['total'] = 10000;
                                achievement['amount'] = getters.TotalWinsAmount < 10000 ? getters.TotalWinsAmount : 10000;
                                break
                            }
                            case "king": {
                                achievement['total'] = 50000;
                                achievement['amount'] = getters.TotalWinsAmount < 50000 ? getters.TotalWinsAmount : 50000;
                                break
                            }
                            case "god": {
                                achievement['total'] = 100000;
                                achievement['amount'] = getters.TotalWinsAmount < 100000 ? getters.TotalWinsAmount : 100000;
                                break
                            }
                            case "streak100": {
                                achievement['total'] = 100;
                                achievement['amount'] = state.userData.dailyStreak < 100 ? state.userData.dailyStreak : 100;
                                break
                            }
                        }
                    }
                }
                catch (e) {
                    console.log('store.getOtherGamesAmount catch: ',e.message,'\nstack: ',e.stack)
                }
            });
        },
        getState(){
            Utils.getState();
        },
        async getUser({ state }, untilUpdated = null) {
            await Utils.getUser(state.loginData,untilUpdated);
        },
        async getWalletName({ state }){
            try {
                if (!state.wallet) {
                    return '';
                }
                const curWallet = state.wallet[state.shop.currency];
                renderTrigger.value++;
                const walletUserName = await curWallet.getUsername();
                return walletUserName?walletUserName:'';
            }
            catch (e) {
                return '';
            }
        },
        openGame({state},name){
            const game=name==='battle'?GameList.slotbattle.battle:GameList[state.gameType][name];

            if (!state.userData){       //Show loggin popup if not login
                state.popupVisible.login=true;
                return;
            }

            if (game.isSoon===true)
                return;

            if (game.isUnlock!==true){      //Show unlock game screen and return
                state.popupUnlockConfirm.gameObj=game;
                state.popupUnlockConfirm.visible=true;
                return;
            }

            let path=state.gameType==='allGames'?'/slot/':'';
            path=state.gameType==='scratchCards'?'/scratch/':path;
            path=state.gameType==='crashGames'?'/crash/':path;
            path=state.gameType==='instantGames'?'/instant/':path;
            path=state.gameType==='spincity'?'/spincity/':path;
            path=name==='battle'?'/slotbattle/':path;
            if (name!=='battle') {
                path += name;
            }
            else{
                setTimeout(()=>{
                    Utils.fullscreenSet(true);
                },1000)
            }

            let query={};
            try {
                query=state.routeQuery?JSON.parse(state.routeQuery):{};
            }catch (e) {
                console.log('store.openGame catch: ',e.message,'\nstack: ',e.stack)
            }
            state.router.push({path:path,query:query});

        },
        async openNotificationAll({ state }){
            if (state.userData && state.userData.notifications){
                const notifications=state.userData.notifications;
                for (let i=0;i<notifications.length;i++){
                    let notification=notifications[i];
                    if (notification.isRead===false) {
                        await Backend.readNotification(state.loginData, notification._id);
                    }
                }
            }
        },
        async openNotification({ state }, id){
            await Backend.readNotification(state.loginData, id);
        },
        openTxLink({ commit, state, getters, dispatch},item){
            if (!item) return;
            MyLog('openTxLink: '+JSON.stringify(item));
            let link;
            if (item.type==='nft' && item.txId){
                if (item.chain === "WAX") {
                    link = "https://wax.bloks.io/transaction/" + item.txId;
                }
                if (item.chain === "SOL") {
                    link = "https://explorer.solana.com/tx/" + item.txId;
                }
                if(item.chain === "RON") {
                    link = "https://app.roninchain.com/tx/" + item.txId;
                }
                if(item.chain === "POL") {
                    link = "https://polygonscan.com/tx/" + item.txId;
                }
                if(item.chain === "BNB") {
                    link = "https://bscscan.com/tx/" + item.txId;
                }
            }

            if (item.chain==='POL'){
                link=`https://polygonscan.com/nft/${item.contract}/${item.nftId}`;
                //s=`https://tofunft.com/nft/polygon/${prize.contract}/${prize.nftId}`;
            }
            if (item.chain==='BNB'){
                link=`https://www.bscscan.com/nft/${item.contract}/${item.nftId}`;
                //s=`https://tofunft.com/nft/bsc/${prize.contract}/${prize.nftId}`;
            }

            if (link) {
                window.open(link,'_blank');
            }
        },
        async rewardsUpdate({ state, dispatch }){
            const objRet = await Backend.rewardsGet({loginData:state.loginData});
            if (objRet.success===true){
                state.rewards=objRet.rewards;
                if (objRet.rewardsCompleted) {
                    const keys=Object.keys(objRet.rewardsCompleted);
                    if (keys.length>0) {
                        keys.forEach((key) => {
                            state.popupRewardPopup.rewardsCompleted[key] = objRet.rewardsCompleted[key];
                        })
                        state.popupRewardPopup.visible=true;
                    }
                }
            }
            return;
        },
        async redeemCode({ state, dispatch }, code){
            const redeem = await Backend.redeemCode(code, state.loginData);
            await dispatch("getUser");
            return redeem;
        },
        async spinWheel({ dispatch, state }, spinScratch) {
            //const skipRequirements=Utils.getEnv() === 'test';
            const skipRequirements=false;
            const spin = await Backend.spinWheel(state.loginData, spinScratch, skipRequirements);
            await dispatch("getUser");
            return spin;
        },
        async unlockGame({ state}, unlockGameData) {
            const unlock = await Backend.unlockGame(
                unlockGameData.game,
                unlockGameData.price,
                state.loginData
            );
            if(unlock.success===true) {
                state.userData.gems-= unlockGameData.price;
                Utils.unlockGames(state,[unlockGameData.game]);
            }
            return unlock;
        },
        async withdrawToken({ commit, state, getters, dispatch}, withdrawTokenData) {
            const withdraw = await Backend.withdrawToken(
                withdrawTokenData.chain,
                withdrawTokenData.nativeToken,
                withdrawTokenData.tokenAddress,
                withdrawTokenData.tokenSymbol,
                withdrawTokenData.address,
                state.loginData
            );
            if(!withdraw.error) {
                await Utils.getUser(state.loginData);
            }
            return withdraw;
        },
    },
    getters: {
        gameStarted: state => state.gameStarted,
        realWidth: state => state.realWidth,
        realHeight: state => state.realHeight,
        deviceWidth: state => state.deviceWidth,
        deviceHeight: state => state.deviceHeight,
        orient: state => state.orient,
        ss: state => state.ss,          //Screen scale for proportional resize elements

        achievObj: state => state.achievObj,
        achievementsStatsUnlocked: state =>{
            if (state.userData){
                return !!state.userData.numBets;
            }
            return false;
        },
        bets: state => state.bets,
        betsAddDeltaTime:state=>{
            let dt=Math.min(state.betsAddDeltaTime,1);
            return dt+'s';
        },
        betsListCurrent: state => state.betsListCurrent,
        blockspinState: state => state.blockspinState,
        boostsObj: state => state.boostsObj,
        btnRad: state =>{
            return 135*state.ss
        },

        firstUserAction: state => state.firstUserAction,
        getLeft:(state)=>{return state.deviceWidth/2-state.realWidth/2;},
        gameType:state => state.gameType,
        gameTypeLeaderboard:(state)=>{          //convert gameType to leaderboard type. Use 'slot' by default
            let s='slot';
            //allGames, scratchCards, crashGames, instantGames,
            s=state.gameType==='scratchCards'?'scratch':s;
            s=state.gameType==='crashGames'?'crash':s;
            s=state.gameType==='instantGames'?'instant':s;
            return s;
        },
        gameTypesNew:state => state.gameTypesNew,
        gameTypesSoon:state => state.gameTypesSoon,
        gameList:state => state.gameList,
        getAvatar: state => {
            return state.userData.avatar;
        },
        getBetsPossible: (state, getters) => {
            let maxBet = 1000;
            if(getters.getUserMonthlyCard) {
                maxBet = getters.getUserMonthlyCard.maxBet;
            }
            let betsPossible = state.blockspinState.betsPossible.filter(bet => bet <= maxBet);
            return betsPossible;
        },
        getChipsBalance: (state) => {
            return state.userData.chips?state.userData.chips:0;
        },
        getClaimPrizes:(state)=>{               //For claim prize
            const prizes=[];
            const stPrizes=state.userData.prizes;
            if (stPrizes) {
                stPrizes.forEach((prize) => {
                    let addPrize=null;
                    let curPrize=null;
                    if (state.prizes.claimType==='unclaimed' && prize.claimed===false)
                        curPrize=prize;

                    if (state.prizes.claimType==='claimed' && prize.txId)
                        curPrize=prize;

                    //state.prizes.blockchain: {null - all prizes / digital - prize type / prize.type==='nft' => prize.chain==WAX/SOL }
                    //&& (prize.type===state.prizes.blockchain || state.prizes.blockchain===null)
                    if (state.prizes.blockchain===''){        //if null then add all prize
                        addPrize=curPrize;
                    }
                    else{
                        if (prize.type==='digital' && state.prizes.blockchain==='digital')
                            addPrize=curPrize;
                        if (prize.type==='nft' && prize.chain && state.prizes.blockchain){
                            if (prize.chain.toLowerCase()===state.prizes.blockchain.toLowerCase())
                                addPrize=curPrize;
                        }
                    }
                    if (addPrize)
                        prizes.push(addPrize);
                });
            }
            return prizes;
        },
        getDailyChipsReward: ( state, getters ) => {
            let numChips = 0;
            //if(state.userData.discordUsername) {          //Give the same chips for all now
                numChips = state.blockspinState.dailyChipsDiscord;
                const level = getters.getLevel;
                numChips *= Math.pow(1+state.blockspinState.dailyChipsPerLevelIncreasePercentage/100, level);
                numChips = Math.ceil(numChips/10) * 10;
            /*} else {
                numChips = state.blockspinState.dailyChipsNonDiscord;
            }//*/
            let dailyChipsBonusPercentage = getters.getUserMonthlyCard? getters.getUserMonthlyCard.dailyChipsBonusPercentage : 0;
            numChips = Math.ceil(numChips * (100+dailyChipsBonusPercentage)/100);

            return numChips;
        },
        getEnv:()=>{
            return Utils.getEnv();
        },
        getSound: state=>state.sound,
        getRecoveryExperience: (state) => {
            return state.userData.recoveryExperience;
        },
        getUserBoosts: (state) => {
            let userBoosts = state.userData.boosts;
            if(userBoosts.xpBoost && userBoosts.xpBoost < state.timestamp) {
                delete userBoosts.xpBoost;
            } else if(userBoosts.megaXpBoost && userBoosts.megaXpBoost < state.timestamp) {
                delete userBoosts.megaXpBoost;
            } else if(userBoosts.daysStreakInsuranceRemaining == 0) {
                delete userBoosts.daysStreakInsuranceRemaining;
            }
            return userBoosts;
        },
        getExperience: (state, getters) => {
            state.updateUserLevel();
            let level = getters.getLevel;

            if(level === 0) {
                return state.userData.experience;
            }

            let xpForCurrentLevel = 1000;
            let xpPerLevelIncrease = 1000;

            for(let i = 0; i<level-1; i++) {
                xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.blockspinState.xpPerLevelIncreasePercentage/100));
                xpForCurrentLevel += xpPerLevelIncrease;
            }

            return state.userData.experience - xpForCurrentLevel;
        },
        getExperienceNext: (state, getters) => {
            state.updateUserLevel();
            let level = getters.getLevel;

            if(level === 100) {
                return 0;
            }

            let xpPerLevelIncrease = 1000;

            for(let i = 0; i<level; i++) {
                xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1+state.blockspinState.xpPerLevelIncreasePercentage/100));
            }

            return xpPerLevelIncrease;
        },
        getGems: (state) => {
            return state.userData.gems?state.userData.gems:0;
        },
        getLevel: state => {
            return state.userLevel?state.userLevel:0;
        },
        userLevel: state =>state.userLevel,         //warning can be null
        getLevelUpRewardAmount: (state, getters) => {
            let rev=0;
            try{
                rev=(Math.floor((getters.getLevel-1) / 10) + 1) * state.blockspinState.levelUpChipsRewardMultiplier;
            }catch (e) {}
            return rev;
        },
        getIsGuest: state => {
            return !!(state.loginData && state.loginData.loginMethod === "guest");
        },
        getIsGuestAndOutOfBets: state => {
            if(state.loginData && state.loginData.loginMethod === "guest") {
                if(state.userData.numBets >= state.blockspinState.guestMaxBets) {
                    return true;
                }
            }
            return false;
        },
        getLoginData: state => state.loginData,

        getMenuRight: (state,getters)=>{
            const val=(getters.gameType==='slotbattle')&&(getters.orient==='H')&&(getters.tgMode===true)?getters.getLeft+60*getters.ss*getters.xMult:getters.getLeft+20*getters.ss*getters.xMult;
            return val;
        },
        getMenuTop:(state,getters)=>{
            const val=(getters.gameType==='slotbattle')&&(getters.orient==='H')&&(getters.tgMode===true)?220*getters.ss*getters.xMult:20*getters.ss*getters.xMult;
            return val;
        },

        getPushNotifToken: state => state.pushNotifToken,

        getReferralLink:(state)=> {
            let link = window.location.protocol + "//" + window.location.hostname;
            if (window.location.port) {
                link += ":" + window.location.port;
            }
            link += "/";
            link += "?ref=" + state.userData.referralCode;

            if (state.tgMode) {
                const s1 = `ref_${state.userData.referralCode}`;
                link = `https://t.me/BlockSpinBot/game?startapp=${s1}`;
            }
            return link;
        },
        getTitle: (state, getters) => {
            const level = getters.getLevel;
            for(let titleLevel of Object.keys(Constants.titles).sort().reverse()) {
                if(level >= titleLevel) {
                    return Constants.titles[titleLevel];
                }
            }
            return "";
        },
        getUserMonthlyCard: state => {
            if(!state.userData.monthlyCard.expiry || state.userData.monthlyCard.expiry < state.blockspinState.timestamp) {
                return null;
            }
            return state.userData.monthlyCard;
        },
        getUsername: state => {
            return state.discordUsername ? state.discordUsername : state.userId;
        },
        isBuyChipsPopupOpen: state => state.isBuyChipsPopupOpen,
        isGuest: state=>{
            if (state.loginData && state.loginData.loginMethod==='guest')
                return true;
            return false;
        },
        isShopPopupOpen: state => state.isShopPopupOpen,
        loginData: state => state.loginData,
        multOur:(state)=>{
            if (state.orient==='H'){            //increase size of elements for horisontal screen orientation
                let max=state.realWidth/(1950*state.ss);  //maximum multiplier for current device width
                return Math.min(1.5,max);
            }
            return 1;
        },
        notificationsFilter: state => state.notificationsFilter,
        notificationsCountUnread:(state)=>{
            let cnt=0;
            if (state.userData && state.userData.notifications) {
                let notifications = state.userData.notifications.filter(notif => !notif.isRead);
                cnt = notifications && notifications.length ? notifications.length : 0;
            }
            return cnt;
        },
        notificationsList:(state)=>{     //selected notifications filter
            const lst=[];
            if (state.userData && state.userData.notifications) {
                state.userData.notifications.forEach((notification)=>{
                    if (state.notificationsFilter==='All')
                        lst.push(notification);
                    if (state.notificationsFilter==='Unread' && !notification.isRead)
                        lst.push(notification);
                    if (state.notificationsFilter==='Unclaimed' && notification.isClaimed!==true && notification.type==='prize')
                        lst.push(notification);
                });
            }
            return lst;
        },
        luckyConfig: state => state.luckyConfig,
        pageCurrent: state => state.pageCurrent,
        pageVisibleUserInfo: state => state.pageVisibleUserInfo,
        popupBuyShopConfirm: state => state.popupBuyShopConfirm,
        popupConfirm: state => state.popupConfirm,
        popupOpenBot: state => state.popupOpenBot,
        popupLevelPopup: state => state.popupLevelPopup,
        popupLuckyWheel: state => state.popupLuckyWheel,
        popupMessage: state => state.popupMessage,
        popupRewardPopup: state => state.popupRewardPopup,
        popupVisible: state => state.popupVisible,
        popupQuickHelp: state => state.popupQuickHelp,
        popupUnlockConfirm: state => state.popupUnlockConfirm,
        popupVip: state => state.popupVip,
        popupNoWallet: state => state.popupNoWallet,
        popupWalletType: state => state.popupWalletType,
        privacy: state => state.privacy,
        prizes: state => state.prizes,
        prizePoolBets: state => state.prizePoolBets,
        prizePoolGames: state => state.prizePoolGames,
        recentBets: state => state.recentBets,
        rewards: state => state.rewards,
        rewardCurrent: state => state.rewardCurrent,
        shop: state => state.shop,
        terms: state => state.terms,
        ticketsBuyCount: state => state.ticketsBuyCount,
        tgMode: state => state.tgMode,
        TotalWinsAmount:state => {
            // return 15000
            let total = 0
            for(const gameName in state.userData.numBets){
                // Slot games only
                if(GameList.allGames.hasOwnProperty(gameName)) {
                    total += (state.userData.numBets[gameName].numWins?state.userData.numBets[gameName].numWins:0);
                }
            }
            return total
        },
        TotalLossAmount:state => {
            // return 15000
            let total = 0
            for(const gameName in state.userData.numBets){
                // Slot games only
                if(GameList.allGames.hasOwnProperty(gameName)) {
                    total += (state.userData.numBets[gameName].numLosses?state.userData.numBets[gameName].numLosses:0);
                }
            }
            return total
        },
        unlockedSlots: state => state.unlockedSlots,
        userData: state => state.userData,
        userId: state => state.userId,
        userName: state => state.userName,
        messages: state => state.messages,
        xMult: state =>{
            if (state.orient==='H')
                return 1.55;
            return 1;
        },
        walletBalance: state => state.walletBalance,
        walletUserName: state => state.walletUserName,
    },
    mutations: {
        btnLeftClick(state){
            let maxLen=state.messages.topPanel.length;
            state.pageCurrent-=1;
            state.pageCurrent=state.pageCurrent<0?maxLen-1:state.pageCurrent;
            Utils.addPayment(wallet,defaultChain);
        },
        btnRightClick(state){
            let maxLen=state.messages.topPanel.length;
            state.pageCurrent+=1;
            state.pageCurrent=state.pageCurrent>=maxLen?0:state.pageCurrent;
            Utils.addPayment(wallet,defaultChain);
        },
        btnMenuClick(state){
            Utils.addPayment(wallet,defaultChain);
            state.pageVisibleUserInfo=true;
            Utils.getUser(state.loginData);
        },
        resize(state,obj){          //{realWidth:appWidth,realHeight:appHeight,ss:ss,orient:orient};
            state.realWidth=obj.realWidth;
            state.realHeight=obj.realHeight;
            state.ss=obj.ss;
            state.orient=obj.orient;
        },
        setExperience(state, experience){
            if(isFinite(experience)) {
                state.userData.experience = experience;
            }
        },
        setLoginData(state, loginData){
            try{
                if (loginData)
                    state.loginData=JSON.parse(JSON.stringify(loginData));
                else
                    state.loginData=null;
                //MyLog('Set login data: ');
                //MyLog(loginData);
            }
            catch(e){
                console.log('store.setLoginData catch: ',e.message,'\nstack: ',e.stack)
            }
        },
        setNewBet(state, newBet) {
            state.bets.slot.mine.unshift(newBet);
            if(state.bets.slot.mine.length > 20) {
                state.bets.slot.mine.pop();
            }
            state.bets.slot.all.unshift(newBet);
            if(state.bets.slot.all.length > 20) {
                state.bets.slot.all.pop();
            }
            state.bets.slot.all.pop();
            if(newBet.chipsWon > state.bets.slot.big[state.bets.slot.big.length-1]) {
                state.bets.slot.big.unshift(newBet);
                if(state.bets.slot.big.length > 20) {
                    state.bets.slot.big.pop();
                }
                state.bets.slot.big.sort((a,b) => b.chipsWon - a.chipsWon);
            }
        },
        setPostSpinUserData(state, user) {
            state.userData.gems = user.gems;
            state.userData.chips = user.chips;
            state.userData.dailyStreak = user.dailyStreak;
            state.userData.experience = user.experience;
            state.userData.achievements = user.achievements;
            state.isInitialLoginData = false;
        },
        setPushNotifToken(state, pushNotifToken) {
            state.pushNotifToken = pushNotifToken;
        },
        setSound(state, sound) {
            if (sound === true || sound === false){
                state.sound = sound;
                localStorage.setItem('sound',sound);
            }

        },
    },

});


