<template>
    <!--   Popup with wallet subtype for wax:wax/anchor, after login continue buy action with buyObj   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <!--   Popup bg static   -->
            <div class="c-wallet_type_2"
                 :style="{'width': 1000*ss*xMult+'px','height': (tgMode?750:980)*ss*xMult+'px','gap': 30*ss*xMult+'px','border-radius':60*ss*xMult+'px',
                    'border':6*ss*xMult+'px solid #00c6fb',
                    'background':getBg,'box-shadow':getShadow}"
            >

                <!--     Information       -->
                <div class="c-wallet_type_column"
                     :style="{'width': 1000*ss*xMult+'px','height': (tgMode?650:880)*ss*xMult+'px',}"
                >
                    <!--     Title       -->
                    <div class="c-wallet_type_text c-wallet_type_2" style="color: #ffffff"
                         :style="{'width': 800*ss*xMult+'px','height': 100*ss*xMult+'px',
                            'font-size': 70*ss*xMult+'px','line-height': 100*ss*xMult+'px',}"
                    >
                        {{messages.m033_01}}
                    </div>
                    <!--     Row with WAX/Phantom       -->
                    <div v-if="getCurrency!=='usd'" class="c-wallet_type_row"
                         :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                            'border-radius':100*ss*xMult+'px',}"
                    >
                        <img :src="getImg1" alt=""
                             :style="{'width': 'auto','height': 110*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                        />
                        <div class="c-wallet_type_text c-wallet_type_2" style="color: #000000"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{getText1}}
                        </div>
                        <!--     Confirm button       -->
                        <div :style="{'width': 230*ss*xMult+'px','height': 100*ss*xMult+'px','margin-right': 10*ss*xMult+'px',}">
                            <ButtonSvgOval :callback="clickLogin1" :callback-wait="true"
                                           :btn-text="messages.m004"
                                           :bg-width="230*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="230*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                           :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <!--     Row with Anchor/Solflare       -->
                    <div v-if="getCurrency!=='bnb' && getCurrency!=='pol' && getCurrency!=='usd' && getCurrency!=='ton'" class="c-wallet_type_row"
                         :style="{'width': 900*ss*xMult+'px','height': 120*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',
                            'border-radius':100*ss*xMult+'px',}"
                    >
                        <img :src="getImg2" alt=""
                             :style="{'width': 'auto','height': 110*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}"
                        />
                        <div class="c-wallet_type_text c-wallet_type_2" style="color: #000000"
                             :style="{'width': 'auto','height': 100*ss*xMult+'px',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{getText2}}
                        </div>
                        <!--     Confirm button       -->
                        <div :style="{'width': 230*ss*xMult+'px','height': 100*ss*xMult+'px','margin-right': 10*ss*xMult+'px',}">
                            <ButtonSvgOval :callback="clickLogin2" :callback-wait="true"
                                           :btn-text="messages.m004"
                                           :bg-width="230*ss*xMult" :bg-height="100*ss*xMult"
                                           :max-width="230*ss*xMult"
                                           :font-size="45*ss*xMult"
                                           :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                           :click-scale="0.95"
                            />
                        </div>
                    </div>

                    <!--     Row with Create account text       -->
                    <div class="c-wallet_type_2"
                         :style="{'width': 900*ss*xMult+'px','height': 'auto',}"
                    >
                        <div class="c-wallet_type_text_2"
                             :style="{'font-size': (getCurrency==='usd'?62:42)*ss*xMult+'px','line-height': (getCurrency==='usd'?80:50)*ss*xMult+'px',}"
                        >
                            {{getText3}}
                        </div>
                        <div class="c-wallet_type_text_2"
                             :style="{'font-size': 45*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                        >
                            <div v-if="getCurrency!=='usd'" style="display: contents;">
                                {{'* '+getTextLink1}}
                                <a @click="click1" style="color:#FFE500;">{{messages.m033_08}}</a>
                            </div>
                            <div v-if="getCurrency!=='bnb' && getCurrency!=='pol' && getCurrency!=='usd' && getCurrency!=='ton'" style="display: contents;">
                                {{', '+getTextLink2}}
                                <a @click="click2" style="color:#FFE500;">{{messages.m033_08}}</a>
                            </div>
                        </div>

                    </div>

                    <!--     Select currency if not tgMode  style="background: #031223;"     -->
                    <div v-if="!tgMode" style="display: contents">
                        <!--   Select your currency   -->
                        <div class="c-wallet_type_column_1"
                             :style="{'width': realWidth-80*ss*xMult+2+'px','height': 220*ss*xMult+1+'px','gap': 0*ss*xMult+'px',}">

                            <!--   Currency type  WAX/BNB/Polygon -->
                            <div class="c-wallet_type_row_1" style="background: rgba(255, 255, 255, 0.15);"
                                 :style="{'width': realWidth-80*ss*xMult+'px','height': 100*ss*xMult+'px','margin-top': 10*ss*xMult+'px',}"
                            >
                                <!--   WAX    -->
                                <div class="c-wallet_type_row_2" @click="clickCurrency('wax')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='wax'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                    'box-shadow': shop.currency==='wax'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                >
                                    <img :src="waxSvg" alt=""
                                         :style="{'width': 120*ss*xMult+'px','height': 'auto',}"
                                    />
                                </div>
                                <!--   BNB    -->
                                <div class="c-wallet_type_row_2" @click="clickCurrency('bnb')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                'border-radius': 20*ss*xMult+'px',
                                'background': shop.currency==='bnb'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                'box-shadow': shop.currency==='bnb'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',}"
                                >
                                    <img :src="imgBnb" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-wallet_type_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_05}}
                                    </div>
                                </div>
                                <!--   Polygon    -->
                                <div class="c-wallet_type_row_2" @click="clickCurrency('pol')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                'border-radius': 20*ss*xMult+'px',
                                'background': shop.currency==='pol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                'box-shadow': shop.currency==='pol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',}"
                                >
                                    <img :src="imgPol" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-wallet_type_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px',
                                'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_06}}
                                    </div>
                                </div>

                            </div>


                            <!--   Currency type   TON / (Solana/USD) || (TelegramStars)-->
                            <div class="c-wallet_type_row_1" style="background: rgba(255, 255, 255, 0.15);"
                                 :style="{'width': realWidth-80*ss*xMult+'px','height': 100*ss*xMult+'px','gap': 0*ss*xMult+'px','margin-top': (tgMode?20:0)*ss*xMult+'px',}"
                            >
                                <!--   Ton    -->
                                <div class="c-wallet_type_row_2" @click="clickCurrency('ton')"
                                     :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                        'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                        'border-radius': 20*ss*xMult+'px',
                                        'background': shop.currency==='ton'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                        'box-shadow': shop.currency==='ton'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                        }"
                                >
                                    <img :src="imgTon" alt=""
                                         :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                    />
                                    <div class="c-wallet_type_ring_info"
                                         :style="{'max-width': 150*ss*xMult+'px','color':'white',
                                            'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                    >
                                        {{messages.m031_11}}
                                    </div>
                                </div>

                                <div style="display: contents;">
                                    <!--   Solana    -->
                                    <div class="c-wallet_type_row_2" @click="clickCurrency('sol')"
                                         :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='sol'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                    'box-shadow': shop.currency==='sol'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                    >
                                        <img :src="solSvg" alt=""
                                             :style="{'width': 60*ss*xMult+'px','height': 'auto',}"
                                        />
                                        <div class="c-wallet_type_ring_info"
                                             :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                        >
                                            {{messages.m031_03}}
                                        </div>
                                    </div>
                                    <!--   USD    -->
                                    <div class="c-wallet_type_row_2" @click="clickCurrency('usd')"
                                         :style="{'width': 'auto','min-width': 131*ss*xMult+'px',
                                    'height': 80*ss*xMult+'px','gap': 10*ss*xMult+'px','padding': 0*ss*xMult+'px '+30*ss*xMult+'px',
                                    'border-radius': 20*ss*xMult+'px',
                                    'background': shop.currency==='usd'?'linear-gradient(92deg, #FCC031 -9.33%, #FF2E2F 92.67%)':getBgUnselect,
                                    'box-shadow': shop.currency==='usd'?'0px 0px 10px 0px rgba(253, 134, 81, 0.80)':'none',
                                    }"
                                    >
                                        <div class="c-wallet_type_ring_info"
                                             :style="{'max-width': 150*ss*xMult+'px',
                                        'font-size': 40*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                                        >
                                            {{messages.m031_09}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import waxSvg from "@/assets/svg/chains/icon_wax.svg";
    import anchorImg from "@/assets/images/logo_anchor.webp";
    import phantomImg from "@/assets/images/logo_phantom.webp";
    import solflareImg from "@/assets/images/logo_solflare.webp";
    import imgMetamask from "@/assets/images/logo_metamask.png";
    import Config from "@/scripts/Config";
    import imgTon from "@/assets/svg/chains/icon_ton";
    import imgPol from "@/assets/svg/chains/icon_polygon";
    import imgBnb from "@/assets/svg/chains/icon_bnb";
    import img_copy from "@/assets/svg/copy_white";
    import solSvg from "@/assets/svg/chains/icon_sol";

    export default defineComponent({
        name: 'WalletType',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupWalletType","tgMode","shop",
                ]),
            getBgUnselect(){
                let txt;
                txt='none';
                txt='rgba(255, 255, 255, 0.30)';
                return txt;
            },
            getImg1(){
                let txt;
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=Utils.getGlobalLink(waxSvg);
                if (param.currency==='sol')
                    txt=Utils.getGlobalLink(phantomImg);
                if (param.currency==='bnb' || param.currency==='pol')
                    txt=Utils.getGlobalLink(imgMetamask);
                if (param.currency==='ton')
                    txt=Utils.getGlobalLink(imgTon);
                txt=txt?txt:'';
                return txt;
            },
            getImg2(){
                let txt;
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=Utils.getGlobalLink(anchorImg);
                if (param.currency==='sol')
                    txt=Utils.getGlobalLink(solflareImg);
                txt=txt?txt:'';
                return txt;
            },
            getText1(){
                let txt;
                const state=this.$store.state
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=state.messages.m033_02;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_04;         //Phantom
                if (param.currency==='bnb' || param.currency==='pol')
                    txt=state.messages.m033_12;         //Metamask
                if (param.currency==='ton')
                    txt=state.messages.m033_16;         //Wax Cloud Wallet
                txt=txt?txt:'';
                return txt;
            },
            getText2(){
                let txt;
                const state=this.$store.state
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=state.messages.m033_03;         //Anchor
                if (param.currency==='sol')
                    txt=state.messages.m033_05;         //Solflare
                txt=txt?txt:'';
                return txt;
            },
            getText3(){
                let txt;
                const state=this.$store.state
                const param=this.getBuyObj();
                txt=state.messages.m033_11;
                if (param.currency==='usd')
                    txt=state.messages.m033_14;         //Anchor

                txt=txt?txt:'';
                return txt;
            },
            getTextLink1(){
                let txt;
                const state=this.$store.state
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=state.messages.m033_06;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_09;         //Phantom
                if (param.currency==='bnb' || param.currency==='pol')
                    txt=state.messages.m033_13;         //Metamask
                if (param.currency==='ton')
                    txt=state.messages.m033_15;         //TON
                txt=txt?txt:'';
                return txt;
            },
            getTextLink2(){
                let txt;
                const state=this.$store.state
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=state.messages.m033_07;         //Anchor Cloud Wallet
                if (param.currency==='sol')
                    txt=state.messages.m033_10;         //Solflare
                txt=txt?txt:'';
                return txt;
            },
            getBg(){
                let txt='linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%)';
                return txt;
            },
            getShadow(){
                let txt='0 0 100px rgba(25,201,243,.6),inset 0 0 100px hsla(0,0%,100%,.5)';
                return txt;
            },
            getCurrency(){
                return this.getBuyObj().currency;
            }

        },
        components: {ButtonSvg,ButtonSvgOval,},
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),imgTon:Utils.getGlobalLink(imgTon),
                imgPol:Utils.getGlobalLink(imgPol),imgBnb:Utils.getGlobalLink(imgBnb),
                img_copy:Utils.getGlobalLink(img_copy),waxSvg:Utils.getGlobalLink(waxSvg),solSvg:Utils.getGlobalLink(solSvg),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupWalletType;
                param.visible=false;
                param.buyObj=null;

            },
            async clickLogin1(){
                const param=this.$store.state.popupWalletType;
                const obj={currency:this.getBuyObj().currency,buyObj:param.buyObj}
                if (obj.currency==='wax')
                    obj.walletType='wax';
                if (obj.currency==='sol')
                    obj.walletType='phantom';
                if (obj.currency==='bnb' || obj.currency==='pol')
                    obj.walletType='metamask';
                if (obj.currency==='ton')
                    obj.walletType='ton';

                this.$store.dispatch('loginWallet',obj)
                param.visible=false;
                param.buyObj=null;
            },
            async clickLogin2(){
                const param=this.$store.state.popupWalletType;
                const obj={currency:this.getBuyObj().currency,buyObj:param.buyObj}
                if (obj.currency==='wax')
                    obj.walletType='anchor';
                if (obj.currency==='sol')
                    obj.walletType='solflare';

                this.$store.dispatch('loginWallet',obj)
                param.visible=false;
                param.buyObj=null;
            },
            click1(){
                let txt;
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=Config.walletLink.wax;         //Wax Cloud Wallet
                if (param.currency==='sol')
                    txt=Config.walletLink.phantom;         //Phantom
                if (param.currency==='bnb' || param.currency==='pol')
                    txt=Config.walletLink.metamask;         //Metamask
                if (param.currency==='ton')
                    txt=Config.walletLink.ton;         //Wax Cloud Wallet
                if (txt)
                    window.open(txt, '_blank').focus();
            },
            click2(){
                let txt;
                const param=this.getBuyObj();
                if (param.currency==='wax')
                    txt=Config.walletLink.anchor;         //anchor Wallet
                if (param.currency==='sol')
                    txt=Config.walletLink.solflare;         //solflare
                if (txt)
                    window.open(txt, '_blank').focus();
            },
            clickCurrency(param){
                this.$store.dispatch('clickCurrency',param);
            },
            getBuyObj(){
                const state=this.$store.state;
                const obj=state.popupWalletType.buyObj;
                const buyObj=obj?obj:{currency:state.shop.currency}
                return buyObj;
            },
        },
    });
</script>

<style lang="css" scoped>

    .c-wallet_type_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        background: rgba(255, 255, 255, 1);
    }

    .c-wallet_type_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_text_2{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: #FFFFFF;

        text-align: -webkit-center;
        /*word-wrap: break-word;/**/
        white-space: break-spaces;
    }

    .c-wallet_type_column_1{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_row_1{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-wallet_type_row_2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-wallet_type_ring_info{
        display: flex;
        justify-content: center;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.20);
        border: 1px solid rgba(255, 255, 255, 0.10);/**/
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #FFFFFF;
    }

</style>
