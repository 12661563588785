<template>
    <!--   Element for Rewards, props: img, msg, prizeVal,    -->
    <div class="c-app_ccs" style="background: hsla(0,0%,100%,.1);"
         :style="{'width': 1000*ss*xMult-16+'px','height': 240*ss*xMult+'px','gap': 0*ss*xMult+'px','border-radius': 50*ss*xMult+'px',}"
    >

        <!-- Image, description   -->
        <div class="c-app_rsc"
             :style="{'width': 'auto','height': 120*ss*xMult+'px','margin-left': 40*ss*xMult+'px','gap': 30*ss*xMult+'px',}">
            <img :src="img" alt=""
                 :style="{'width': 100*ss*xMult+'px','height': 100*ss*xMult+'px',}"/>

            <div class="c-app_ccs"
                 :style="{'width': 790*ss*xMult+'px','max-width': 790*ss*xMult+'px','height': 120*ss*xMult+'px',}">
                <!--  Reward description text -->
                <div class="c-reward_elem_text c-app_css" style="white-space: pre-wrap; text-align: -webkit-left;"
                     :style="{'width': 'auto','max-width': 760*ss*xMult+'px','max-height': 100*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                    {{msg}}
                </div>
            </div>
        </div>

        <!-- Date / progress, progressbar, chips prize   -->
        <div class="c-app_rbc"
             :style="{'width': 940*ss*xMult-16+'px','height': 100*ss*xMult+'px','margin-left': 40*ss*xMult+'px','gap': 30*ss*xMult+'px',}">

            <!--  chips prize -->
            <div class="c-app_rbc" style="background: linear-gradient(94deg, rgb(0, 198, 251) 0.27%, rgb(0, 91, 234) 105.74%);"
                 :style="{'width': 445*ss*xMult-8+'px','height': 70*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'opacity': rewardStatus===2?'1':'0.2',}">
                <div class="c-reward_elem_text c-app_ccc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px','margin-left': 20*ss*xMult+'px',}">
                    {{messages.m050_03}}
                </div>
                <div class="c-app_rbc"
                     :style="{'width': 'auto','height': 60*ss*xMult+'px','margin-right': 20*ss*xMult+'px','gap': 10*ss*xMult+'px',}">
                    <div class="c-reward_elem_text c-app_ccc"
                         :style="{'width': 'auto','height': 60*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}">
                        {{getPrizeVal}}
                    </div>
                    <img :src="img_chips" alt=""
                         :style="{'width': 50*ss*xMult+'px','height': 50*ss*xMult+'px',}"/>
                </div>
            </div>

            <!--  prize status progress/done/not avail -->
            <div class="c-app_ccc"
                 :style="{'width': 300*ss*xMult-8+'px','height': 80*ss*xMult+'px','border-radius': 40*ss*xMult+'px',
                    'margin-right': 60*ss*xMult+'px','background':getBackground}">
                <img :src="getImage" alt=""
                     :style="{'width': 70*ss*xMult+'px','height': 70*ss*xMult+'px',}"/>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import img_chips from "@/assets/svg/chips";
    import {priceFraction} from "@/classes/Constants";
    import img_lock from "@/assets/svg/icon_lock.svg";
    import img_progress from "@/assets/svg/icon_progress.svg";
    import img_v from "@/assets/svg/icon_v.svg";

    export default defineComponent({
        name: 'RewardElem',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult","messages"]),
            getPrizeVal(){
                let val=0;
                try{
                    const points=priceFraction[this.getPrizeType()]?priceFraction[this.getPrizeType()]:0;
                    val=Utils.isNumber(this.prizeVal)?parseFloat(this.prizeVal):0;
                    val=val.toFixed(points);
                }
                catch (e) {}
                return val;
            },
            getBackground(){
                // 1 - done/ 2 - progress/ 3 - not avail
                //border-radius: 50px;
                //let s='#717171';

                let bg='linear-gradient(95deg, #b6b6b6 -16.14%, #717171 137.96%)';
                bg=this.rewardStatus===1?'linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)':bg;
                bg=this.rewardStatus===2?'linear-gradient(95deg, #FFC83C -9.98%, #DC8400 118.99%)':bg;
                return bg;
            },
            getImage(){
                // 1 - done/ 2 - progress/ 3 - not avail
                //border-radius: 50px;
                //let s='#717171';

                let bg=this.img_lock;
                bg=this.rewardStatus===1?this.img_v:bg;
                bg=this.rewardStatus===2?this.img_progress:bg;
                return bg;
            }
        },
        data(){
            return {
                img_chips:Utils.getGlobalLink(img_chips),img_progress:Utils.getGlobalLink(img_progress),
                img_lock:Utils.getGlobalLink(img_lock),img_v:Utils.getGlobalLink(img_v),
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        beforeMount() {

        },
        mounted() {
        },
        methods: {
            getPrizeType(){
                let s='chips';
                s=this.prizeType==='gems'?this.prizeType:s;
                return s;
            }
        },

        props: {
            img: {
                type: String,
                default: ""
            },
            msg: {
                type: String,
                default: ""
            },
            prizeType: {
                type: String,
                default: 'chips'
            },
            prizeVal: {
                type: String,
                default: ''
            },
            prizeImage: {
                type: String,
                default: ''
            },
            rewardStatus: {
                type: Number,       // 1 - done/ 2 - progress/ 3 - not avail
                default: 3
            },

        },

    });
</script>

<style lang="css" scoped>
    .c-reward_elem_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
        white-space: nowrap;
    }

</style>
