import {createRouter, createWebHistory } from 'vue-router';

import Main from "./Main.vue";
import Home from "./Home.vue";
import PageSlot from "./pages/PageSlot";
import PageScratch from "./pages/PageScratch";
import PageCrash from "./pages/PageCrash";
import PageInstant from "./pages/PageInstant";
import PageSpincity from "./pages/PageSpincity";
import PageBattle from "@/vue/pages/battle_page/PageBattle";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            //redirect: '/home',
            component: Main,
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: Home,
                },
                {
                    path: '/slot/:gameId',
                    name: 'slot',
                    component: PageSlot,
                },
                {
                    path: '/scratch/:gameId',
                    name: 'scratch',
                    component: PageScratch,
                },
                {
                    path: '/crash/:gameId',
                    name: 'crash',
                    component: PageCrash,
                },
                {
                    path: '/instant/:gameId',
                    name: 'instant',
                    component: PageInstant,
                },
                {
                    path: '/spincity/:gameId',
                    name: 'spincity',
                    component: PageSpincity,
                },
                {
                    path: '/slotbattle/',
                    name: 'slotbattle',
                    component: PageBattle,
                },
            ]
        },
    ],
});

export default router;
