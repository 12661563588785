import Utils from "@/scripts/Utils";
import Config from "@/scripts/Config";

let backendUri = 'https://api.blockspingaming.com/';
let backendTelegram = 'https://apitelegram.blockspingaming.com/';
let spincityBackendUri = 'https://api.spincitytycoon.com/api/';
let wheelBackendUri = 'https://wheelapi.blockspingaming.com/api/';
if(window.location.hostname.includes("test.") || window.location.hostname.includes("localhost")) {
    backendUri = 'https://apitest.blockspingaming.com/';
    backendTelegram = 'https://apitesttelegram.blockspingaming.com/';
}

if (location.protocol !== 'https:') {
    backendUri = backendUri.replace('https', 'http');
}

export default new class BackendCommunication {

    async betInstant(game, bet, lines, difficulty, choice, loginData) {
        const body = {
            game,
            bet,
            lines,
            difficulty,
            choice,
            loginData,
        };

        return await this.request(backendUri + 'betinstant/', body);
    }

    async bonus(game, choice, loginData) {
        const body = {
            game,
            choice,
            loginData
        };

        return await this.request(backendUri + 'bonus/', body);
    }

    async buyChips(chipsPackage, quantity, totalPrice, chain, walletUsername, loginData) {
        const body = {
            chipsPackage,
            quantity,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buychips/', body);
    }

    async buyItemTelegram(obj) {
        //{chipsPackage, quantity, totalPrice, tgInitData, title, description, photo_url, photo_width, photo_height}
        const body=obj;
        return await this.request(backendTelegram + 'buyitemtelegram/', body);
    }

    async buyMonthlyCard(monthlyCardType, totalPrice, chain, walletUsername, loginData) {

        const body = {
            monthlyCardType,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buymonthlycard/', body);
    }

    async buyBoost(boostType, quantity, totalPrice, buyWithGems, chain, walletUsername, loginData) {

        const body = {
            boostType,
            quantity,
            totalPrice,
            buyWithGems,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyboost/', body);
    }

    async buyJackbotPremiumUser(totalPrice, chain, walletUsername, loginData) {

        const body = {
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyjackbotpremiumuser/', body);
    }

    async buyJackbotPremiumServer(quantity, totalPrice, chain, walletUsername, loginData) {

        const body = {
            quantity,
            totalPrice,
            chain,
            walletUsername,
            loginData
        };

        return await this.request(backendUri + 'buyjackbotpremiumserver/', body);
    }

    async buyScratch(game, numCards, loginData) {
        const body = {
            game,
            numCards,
            loginData,
        };

        return await this.request(backendUri + 'buyscratch/', body);
    }

    async buyTickets(ticketType, quantity, totalPrice, loginData) {

        const body = {
            ticketType,
            quantity,
            totalPrice,
            loginData
        };

        return await this.request(backendUri + 'buytickets/', body);
    }

    async claimChips(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'claimchips/', body);
    }

    async claimNotification(loginData, notificationId) {
        const body = {
            loginData,
            notificationId
        };

        return await this.request(backendUri + 'claimnotification/', body);
    }

    async claimNfts(chain, address, loginData) {
        const body = {
            chain,
            address,
            loginData
        };

        return await this.request(backendUri + 'claimnfts/', body);
    }

    async collect(game, loginData) {
        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'collect/', body);
    }

    async connectDiscord(loginData, accessToken, tokenType) {

        const body = {
            loginData,
            accessToken,
            tokenType
        };

        return await this.request(backendUri + 'connectdiscord/', body);
    }

    async connectTelegram(obj){
        //{env, loginData, telegramMergeCode}
        const body = obj;
        return await this.request(backendUri + 'connecttelegram/', body);
    }

    async discordGetSecret(obj){
        //{tgInitData}
        const body=obj;
        return await this.request(backendTelegram + 'discordgetsecret/', body);
    }

    async enterRaffle(raffleType, quantity, loginData) {
        const body = {
            raffleType,
            quantity,
            loginData
        };
        return await this.request(backendUri + 'enterraffle/', body);
    }

    async getBets(loginData) {

        const body = {
            loginData
        };

        return await this.request(backendUri + 'getbets/', body);
    }

    async getLeaderboard(loginData) {
        const body = {
            loginData
        };

        return await this.request(backendUri + 'getleaderboard/', body);
    }

    async getplayerslotbattleresult(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattleresult/', body);
    }

    async getplayerslotbattlespin(spinNumber, opponentSpin, loginData) {
        const body = {
            spinNumber,
            opponentSpin,
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattlespin/', body);
    }

    //It will return { slotBattleStatus: {status: "idle"/"waiting"/"battle", roomBet: 1000, game: "kingarthur"} }
    async getPlayerSlotBattleStatus(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getplayerslotbattlestatus/', body);
    }

    async getPrizeCode(prizeId, loginData) {

        const body = {
            prizeId,
            loginData
        };

        return await this.request(backendUri + 'getprizecode/', body);
    }

    async getSlotBattleRooms(loginData) {
        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getslotbattlerooms/', body);
    }

    async getSpincityState(){
        const body = {

        }

        return await this.request(spincityBackendUri + 'getstate/', body);
    }

    async getState() {
        const body = {
        };

        return await this.request(backendUri + 'getstate/', body);
    }

    async getUser(loginData) {

        const body = {
            loginData,
        };

        return await this.request(backendUri + 'getuser/', body);
    }

    async init(game, loginData) {
        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'init/', body);
    }

    async initCrash(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initcrash/', body);
    }

    async initInstant(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initinstant/', body);
    }

    async initScratch(game, loginData) {

        const body = {
            game,
            loginData,
        };

        return await this.request(backendUri + 'initscratch/', body);
    }

    initTelegram(obj){
        //obj={tgInitData,userId}
        try {
            const body = obj;
            this.request(backendTelegram + 'inittelegram/', body).then(()=>{});
        }catch (e) {

        }
    }

    async joinSlotBattle(game, roomBet, loginData) {
        const body = {
            game,
            roomBet,
            loginData,
        };

        return await this.request(backendUri + 'joinslotbattle/', body);
    }

    async login(loginMethod, accessToken, tokenType, code, redirectUri, referralCode, jumpTask, loginData) {
        const body = {
            loginMethod,
            accessToken,
            tokenType,
            code,
            redirectUri,
            referralCode,
            jumpTask,
            loginData
        };

        return await this.request(backendUri + 'login/', body);
    }

    async loginSpincity(loginData){
        const body = {
            loginData
        }

        return await this.request(spincityBackendUri + 'login/', body);
    }

    async logout(loginData) {

        const body = {
            loginData
        };

        return await this.request(backendUri + 'logout/', body);
    }

    async joinTeam(game, team, loginData) {

        const body = {
            game,
            team,
            loginData,
        };

        return await this.request(backendUri + 'jointeam/', body);
    }

    async purchaseInfoGet(obj){         //get info about last purchase
        //obj={tgInitData}
        //return value: {txId:null, purchaseObj: {chipsPackage, quantity, totalPrice, tgInitData, title, description, photo_url, photo_width, photo_height}};
        const body=obj;
        return await this.request(backendTelegram + 'purchaseinfoget/', body);
    }

    async purchaseInfoCancel(obj){         //send cancel signal for "last purchase"
        //obj={tgInitData}
        const body=obj;
        return await this.request(backendTelegram + 'purchaseinfocancel/', body);
    }

    async readNotification(loginData, notificationId) {

        const body = {
            loginData,
            notificationId
        };

        return await this.request(backendUri + 'readnotification/', body);
    }

    async redeemCode(code, loginData) {

        const body = {
            code,
            loginData,
        };

        return await this.request(backendUri + 'redeemcode/', body);
    }

    async rewardsGet(obj) {
        //{loginData}
        const buri=window.location.hostname.includes("localhost")?'http://localhost:3011/':backendTelegram;

        //{rewards:{tasks,daily,social},rewardsNew:[]}
        const objRet=await this.request(buri + 'rewardsget/', obj);
        return objRet;
    }

    async spin(game, loginData, lines, bet) {
        const body = {
            game,
            loginData,
            lines,
            bet
        };

        return await this.request(backendUri + 'spin/', body);
    }

    async spinWheel(loginData, spinScratch, skipRequirements) {

        const body = {
            loginData,
            spinScratch,
            skipRequirements,
            isMobile:true,
        };

        return await this.request(backendUri + 'spinwheel/', body);
    }

    async scratchCard(game, turbo, numCards, loginData) {
        const body = {
            game,
            turbo,
            numCards,
            loginData,
        };

        return await this.request(backendUri + 'scratchcard/', body);
    }

    async setPushNotifToken(userId, token){
        const body = {
            userId,
            token
        }

        return await this.request(wheelBackendUri + 'setpushnotiftoken/', body);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async unlockGame(game, price, loginData) {
        const body = {
            game,
            price,
            loginData
        };

        return await this.request(backendUri + 'unlockgame/', body);
    }

    async withdrawToken(chain, nativeToken, tokenAddress, tokenSymbol, address, loginData) {

        const body = {
            chain,
            nativeToken,
            tokenAddress,
            tokenSymbol,
            address,
            loginData
        };

        return await this.request(backendUri + 'withdrawtoken/', body);
    }

    async request(uri, body) {
        let url = window.location.hostname;
        if(url.includes("test.") || url.includes("localhost")) {
            body.env = "test";
        }else{
            body.env = "prod";
        }

        let maxTries = 10;
        let tries = 0;

        while(true) {
            try {
                return await this.fetchRequest(uri, body);
            } catch(e) {
                if(tries < maxTries && e.message == "Failed to fetch") {
                    tries++;
                    await this.sleep(2000);
                } else {
                    return { error: e.message }
                }
            }
        }

    }

    async fetchRequest(uri, body) {

        const response = await fetch(uri, {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        });

        return await response.json();
    }

}
