import Config from "@/scripts/Config";

import bigwinner from "@/assets/images/achievements/bigwinner.webp";
import chickendinner from "@/assets/images/achievements/chickendinner.webp";
import excalibur from "@/assets/images/achievements/excalibur.webp";
import chad  from "@/assets/images/achievements/chad.webp";
import godluck from "@/assets/images/achievements/godluck.webp";
import trophy  from "@/assets/images/achievements/trophy.webp";
import master  from "@/assets/images/achievements/master.webp";
import slotsking  from "@/assets/images/achievements/slotsking.webp";
import slotsgod  from "@/assets/images/achievements/slotsgod.webp";
import trainwreck  from "@/assets/images/achievements/trainwreck.webp";
import slotsohollic  from "@/assets/images/achievements/slotsohollic.webp";
import slotaddict  from "@/assets/images/achievements/slotaddict.webp";
import slottycoon  from "@/assets/images/achievements/slottycoon.webp";
import vip from "@/assets/images/achievements/vip.webp";
import newyear2023 from "@/assets/images/achievements/newyear2023.webp";
import newyear2024 from "@/assets/images/achievements/newyear2024.webp";
import newyear2025 from "@/assets/images/achievements/newyear2025.webp";
import streak100 from "@/assets/images/achievements/streak100.webp";
import outlaw from "@/assets/images/achievements/outlaw.webp";
import kingpin from "@/assets/images/achievements/kingpin.webp";
import zhuhou from "@/assets/images/achievements/zhuhou.webp";
import ghost from "@/assets/images/achievements/ghost.webp";
import scarabs from "@/assets/images/achievements/scarabs.webp";
import topsecret from "@/assets/images/achievements/topsecret.webp";
import shogun from "@/assets/images/achievements/shogun.webp";
import surfer from "@/assets/images/achievements/surfer.webp";
import pirate from "@/assets/images/achievements/pirate.webp";
import jungleking from "@/assets/images/achievements/jungleking.webp";
import genie from "@/assets/images/achievements/genie.webp";
import progambler from "@/assets/images/achievements/progambler.webp";
import goldenbiker from "@/assets/images/achievements/goldenbiker.webp";
import gravegambler from "@/assets/images/achievements/gravegambler.webp";
import slotlegend from "@/assets/images/achievements/slotlegend.webp";
import heistclown from "@/assets/images/achievements/heistclown.webp";
import mcslotter from "@/assets/images/achievements/mcslotter.webp";
import grandchief from "@/assets/images/achievements/grandchief.webp";
import hulaace from "@/assets/images/achievements/hulaace.webp";
import carioca from "@/assets/images/achievements/carioca.webp";
import shadowwarrior from "@/assets/images/achievements/shadowwarrior.webp";
import caesar from "@/assets/images/achievements/caesar.webp";
import sacrebleuplayer from "@/assets/images/achievements/sacrebleuplayer.webp";
import superstar from "@/assets/images/achievements/superstar.webp";

const achCnt=Config.slotAchievementsPrize.toLocaleString();

export default {
    chad:{
        title: 'Slots Chad',
        name:'chad',
        description:'Earning all the badges',
        image:chad,
    },
    trophy:{
        title: 'Member Trophy',
        name:'trophy',
        description:'Take part in a Discord event',
        image:trophy,
    },
    master:{
        title:'Slots Master',
        name:'master',
        description:'Winning 10,000 slots games',
        image:master,
    },
    king:{
        title:'Slots King',
        name:'king',
        description:'Winning 50,000 slots games',
        image:slotsking,
    },
    god:{
        title:'Slots God',
        name:'god',
        description:'Winning 100,000 slots games',
        image:slotsgod,
    },
    trainwreck:{
        title:'Slots Trainwreck',
        name:'trainwreck',
        description:'Losing 10,000 slots games',
        image:trainwreck,
    },
    slotsohollic:{
        title:'Slotsohollic',
        name:'slotsohollic',
        description:'Playing 10,000 slots games',
        image:slotsohollic,
    },
    addict:{
        title:'Slots Addict',
        name:'addict',
        description:'Playing 50,000 slots games',
        image:slotaddict,
    },
    tycoon:{
        title:'Slots Tycoon',
        name:'tycoon',
        description:'Playing 100,000 slots games',
        image:slottycoon,
    },
    vip:{
        title:'VIP',
        name:'vip',
        description:'Spending more than $100 on chips',
        image:vip,
    },
    newyear2023:{
        title:'New Year 2023, New Me',
        name:'newyear2023',
        description:'Playing a game in the last week of 2022',
        image:newyear2023,
    },
    newyear2024:{
        title:'New Year 2024, New Me',
        name:'newyear2024',
        description:'Playing a game in the last week of 2023',
        image:newyear2024,
    },
    newyear2025:{
        title:'New Year 2025, New Me',
        name:'newyear2025',
        description:'Playing a game in the last week of 2024',
        image:newyear2025,
    },
    streak100:{
        title:'100 streak',
        name:'streak100',
        description:'100 daily streak',
        image:streak100,
    },
    bigwinner:{
        title:'Big winner',
        name:'bigwinner',
        description:'Win at least 10,000 chips in one spin',
        image:bigwinner,
    },
    godluck:{
        title:'Godluck',
        name:'godluck',
        description:`${achCnt} bets on Gold of Gods`,
        image:godluck,
    },
    chickendinner:{
        title:'Winner winner chicken dinner',
        name:'chickendinner',
        description:'Winning the Jackpot',
        image:chickendinner,
    },
    excalibur:{
        title:'Excalibur',
        name:'excalibur',
        description:`${achCnt} bets on king Arthur`,
        image:excalibur,
    },
    outlaw:{
        title:'Outlaw',
        name:'outlaw',
        description:`${achCnt} bets on Wild West`,
        image:outlaw,
    },
    kingpin:{
        title:'Narcos kingpin',
        name:'kingpin',
        description:`${achCnt} bets on Cartel`,
        image:kingpin,
    },
    zhuhou:{
        title:'Zhuhou',
        name:'zhuhou',
        description:`${achCnt} bets on Tiger land`,
        image:zhuhou,
    },
    ghost:{
        title:'Ghost',
        name:'ghost',
        description:`${achCnt} bets on Night of Fear`,
        image:ghost,
    },
    scarabs:{
        title:'Golden Scarabs',
        name:'scarabs',
        description:`${achCnt} bets on Cleopatra`,
        image:scarabs,
    },
    topsecret:{
        title:'Top Secret',
        name:'topsecret',
        description:`${achCnt} bets on SpyMaster`,
        image:topsecret,
    },
    shogun:{
        title:'Shogun',
        name:'shogun',
        description:`${achCnt} bets on Samurai Spirit`,
        image:shogun,
    },
    surfer:{
        title:'Silver Surfer',
        name:'surfer',
        description:`${achCnt} bets on Surfin' California`,
        image:surfer,
    },
    pirate:{
        title:'Pirate treasure',
        name:'pirate',
        description:`${achCnt} bets on Treasure Island Quest`,
        image:pirate,
    },
    jungleking:{
        title:'King of the jungle',
        name:'jungleking',
        description:`${achCnt} bets on The Big Five`,
        image:jungleking,
    },
    genie:{
        title:'Genie',
        name:'genie',
        description:`${achCnt} bets on Aladdin`,
        image:genie,
    },
    progambler:{
        title:'Pro Gambler',
        name:'progambler',
        description:`${achCnt} bets on Vegas Classic`,
        image:progambler,
    },
    goldenbiker:{
        title:'Golden Biker',
        name:'goldenbiker',
        description:`${achCnt} bets on Route66`,
        image:goldenbiker,
    },
    gravegambler:{
        title:'Graveyard Gambler',
        name:'gravegambler',
        description:`${achCnt} bets on Dia De Muertos`,
        image:gravegambler,
    },
    slotlegend:{
        title:'Slot Legend',
        name:'slotlegend',
        description:`${achCnt} bets on Northern Legends`,
        image:slotlegend,
    },
    heistclown:{
        title:'Heist Clown',
        name:'heistclown',
        description:`${achCnt} bets on Tokyo Run`,
        image:heistclown,
    },
    mcslotter:{
        title:'M.C. Slotter',
        name:'mcslotter',
        description:`${achCnt} bets on L.A. Jam`,
        image:mcslotter,
    },
    grandchief:{
        title:'Grand Chief',
        name:'grandchief',
        description:`${achCnt} bets on Apache Trail`,
        image:grandchief,
    },
    hulaace:{
        title:'Hula Ace',
        name:'hulaace',
        description:`${achCnt} bets on Hula Ace`,
        image:hulaace,
    },
    carioca:{
        title:'Carioca',
        name:'carioca',
        description:`${achCnt} bets on Copacabana`,
        image:carioca,
    },
    caesar:{
        title:'Caesar',
        name:'caesar',
        description:`${achCnt} bets on Imperial Rome`,
        image:caesar,
    },
    shadowwarrior:{
        title:'Shadowwarrior',
        name:'shadowwarrior',
        description:`${achCnt} bets on Ninja Strike`,
        image:shadowwarrior,
    },
    sacrebleuplayer:{
        title:'Sacrebleu Player',
        name:'sacrebleuplayer',
        description:`${achCnt} bets on Paris Paris`,
        image:sacrebleuplayer,
    },
    superstar:{
        title:'Superstar',
        name:'superstar',
        description:`${achCnt} bets on Bollywood`,
        image:superstar,
    },
}
