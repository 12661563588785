<template>
    <link rel="preload" fetchpriority="high" as="image" :href="iconSlot" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconScratch" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconCrash" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconInstant" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconSpincity" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconPoker" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconSlotBattle" type="image/webp">
    <link rel="preload" fetchpriority="high" as="image" :href="iconCasino" type="image/webp">

    <div class="c-device"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

        <div class="c-device_bg"
             :style="{'width': realWidth+'px','height': realHeight+'px',}">

            <TopPanel/>
            <PageOur v-if="pageCurrent===0"/>
            <PageInfo v-if="pageCurrent===1"/>
            <PrizePoolBets v-if="pageCurrent===2"/>
            <PrizePoolGames v-if="pageCurrent===3"/>
            <RecentBets v-if="pageCurrent===4"/>

            <ReddemPopup v-if="popupVisible.redeemPopup===true"/>
            <UnlockConfirm v-if="popupUnlockConfirm.visible===true" />

        </div>
        <!--UserInfo v-if="pageVisibleUserInfo"/-->
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import icon1 from "../assets/images/about-us-banner.webp";
    import TopPanel from "./TopPanel";
    import UserInfo from "./account/UserInfo";
    import PageOur from "./pages/PageOur";
    import UnlockConfirm from "@/vue/pages/UnlockConfirm";
    import PrizePoolBets from "@/vue/pages/PrizePoolBets";
    import PageInfo from "@/vue/pages/PageInfo";
    import PrizePoolGames from "@/vue/pages/PrizePoolGames";
    import RecentBets from "@/vue/pages/RecentBets";
    import ReddemPopup from "@/vue/pages/components/ReddemPopup";

    import iconSlot from "@/assets/images/icon_slot.webp";
    import iconScratch from "@/assets/images/icon_scratch.webp";
    import iconCrash from "@/assets/images/icon_crash.webp";
    import iconInstant from "@/assets/images/icon_instant.webp";
    import iconSpincity from "@/assets/images/icon_spincity.webp";
    import iconPoker from "@/assets/images/icon_poker.webp";
    import iconSlotBattle from "@/assets/images/icon_slot_battle.webp";
    import iconCasino from "@/assets/images/icon_casino.webp";
    import Utils from "@/scripts/Utils";

    export default {
        name: "DeviceScreen",
        components: {PageOur, UserInfo, TopPanel,UnlockConfirm,PrizePoolBets,PageInfo,PrizePoolGames,RecentBets,ReddemPopup,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","pageVisibleUserInfo","pageCurrent","popupUnlockConfirm","popupVisible"]),
        },
        created() {

        },
        mounted() {
            Utils.getLeaderboard();
        },
        beforeMount() {
            Utils.stopLeaderboard();
        },
        data: function () {
            return {
                icon1: icon1,iconSlot,iconScratch,iconCrash,iconInstant,iconPoker,iconCasino,iconSpincity,iconSlotBattle,
            }
        },
        methods:{

        },
    }

</script>

<style lang="css" scoped>

    .c-device{
        display: flex;
        overflow: hidden;
        position: absolute;
        /*transform: translate(50%, -50%);/**/
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(255, 255, 255, 0.1);/**/
        /*border: 4px solid rgba(0, 255, 0, 0.5);/**/
    }

    .c-device_bg{
        display: flex;
        overflow: hidden;
        position: relative;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

</style>
