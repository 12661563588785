<template>
    <div class="c-iframe"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
        <iframe
                v-if="scratchCards[gameName]"
                id="mainframe"
                class="c-iframe_bg"
                :style="{'width': realWidth+'px','height': realHeight+'px',}"
                :src="gameUri"
        ></iframe>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameList from "../../scripts/GameList";
    import Backend from '../../classes/backend.js';
    import Config from "../../scripts/Config";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import menuBtnSvg from "@/assets/svg/menu_btn.svg";
    import Utils from "@/scripts/Utils";

    export default {
        name: "PageScratch",
        components: {ButtonSvg,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","btnRad",]),
            gameUri(){
                const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
                let uri='';
                uri=gameUri+GameList.scratchCards[this.gameName].uri;
                return uri;
            },
            gameName() {
                return this.$route.params.gameId.split("-").join("");
            },
            chips() {
                return this.$store.state.userData.chips;
            },
        },
        created() {
            MyLog('game page created');
            window.addEventListener("message", this.receiveMessage, false);

            let path='/scratch/'+this.gameName;
            localStorage.setItem("path",path);
        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
        },

        data: function () {
            return {
                scratchCards: GameList.scratchCards,
                nextUser: {},
                menuBtnSvg:Utils.getGlobalLink(menuBtnSvg),
            }
        },
        methods:{
            async receiveMessage(message) {
                if (typeof message.data !== "string") {
                    return;
                }
                const data = JSON.parse(message.data);
                console.log(data);
                switch (data.action) {
                    case "init": {
                        this.gameWindow = message.source;
                        if (this.$store.state.loginData) {
                            let initData = await Backend.initScratch(
                                this.scratchCards[this.$route.params.gameId].name,
                                this.$store.state.loginData
                            );
                            if (initData.error) {
                                alert(initData.error);
                            } else {
                                initData["sound"] = this.$store.state.sound;
                                message.source.postMessage(JSON.stringify(initData), "*");
                                if (initData.balance) {
                                    this.$store.state.userData.chips=initData.balance;
                                }
                                if (initData.jackpot) {
                                    this.$store.state.blockspinState.jackpot=initData.jackpot;
                                }
                            }
                        } else {
                            this.initOnLogin = true;
                        }
                        break;
                    }
                    case "buyCards": {
                        this.gameWindow = message.source;
                        let numCards = data.numCards;
                        if (numCards && Number.isSafeInteger(numCards)) {
                            let buyCardsData = await Backend.buyScratch(
                                this.scratchCards[this.$route.params.gameId].name,
                                numCards,
                                this.$store.state.loginData
                            );
                            buyCardsData.action = "buyCards";
                            if (buyCardsData.error) {
                                buyCardsData.success = false;
                                buyCardsData.balance = this.chips;
                                if (!buyCardsData.numCards) {
                                    buyCardsData.numCards = 0;
                                }
                            } else {
                                buyCardsData.numCards = numCards;
                            }
                            message.source.postMessage(JSON.stringify(buyCardsData), "*");
                            this.$store.state.userData.chips=buyCardsData.balance
                        }
                        break;
                    }
                    case "scratch": {
                        if (!this.$store.getters.getIsGuestAndOutOfBets) {
                            this.gameWindow = message.source;
                            let turbo = data.turbo;
                            let numCards = data.numCards;
                            let scratchData = await Backend.scratchCard(
                                this.scratchCards[this.$route.params.gameId].name,
                                turbo,
                                numCards,
                                this.$store.state.loginData
                            );
                            message.source.postMessage(JSON.stringify(scratchData), "*");
                            this.$store.state.userData.gems=scratchData.gems;

                            this.$store.commit("setExperience", scratchData.experience);
                            this.nextChips = scratchData.balance;

                            if (this.$store.getters.getIsGuest) {
                                this.$store.state.userData.numBets+=1;
                            }
                            setTimeout(()=>{
                                this.$store.state.updateUserLevel();
                            },1000);

                        } else {
                            this.$store.state.userData.numBets+=1;
                        }
                        break;
                    }
                    case "buyChips": {
                        this.$store.state.isBuyChipsPopupOpen=true;
                        break;
                    }
                    case "home": {
                        this.$router.push({path:Config.defaultPath,query:this.$route.query});
                        break;
                    }
                    case "soundOn": {
                        this.$store.commit("setSound", true);
                        break;
                    }
                    case "soundOff": {
                        this.$store.commit("setSound", false);
                        break;
                    }
                }
                if (data === "scratchComplete" || (data && data.action && data.action === "scratchComplete")) {
                    this.$store.state.userData.chips=this.nextChips;
                }

            },
        },
        watch:{
            chips(value) {
                if (this.gameWindow) {
                    this.gameWindow.postMessage(
                        JSON.stringify({ action: "balance", balance: value }),
                        "*"
                    );
                }
            },
        },
    }

</script>

<style lang="css" scoped>


</style>
