<template>
    <router-view />
    <!--        Menu button     -->
    <div class="c-main unselectable"
         :style="{'right': getMenuRight+'px','top': getMenuTop+'px','width': btnRad*xMult+'px','height': btnRad*xMult+'px'}">
        <div class="c-main_bg"
             :style="{'width': btnRad*xMult+'px','height': btnRad*xMult+'px',}">

            <div v-if="!pageVisibleUserInfo" class="c-top_btn_menu" style="position: absolute"
                 :style="{'width': btnRad*xMult+'px','height': btnRad*xMult+'px',}">
                <div class="c-top_btn_menu" style="position: relative"
                     :style="{'width': btnRad*xMult+'px','height': btnRad*xMult+'px',}">
                    <ButtonSvg :svg-normal="menuBtnSvg" :svg-clicked="menuBtnSvg"
                               :bg-width="btnRad*xMult"
                               :bg-height="btnRad*xMult"
                               :callback="btnMenuClick" :callback-wait="true"
                    />
                    <NotificationsNumber v-if="notificationsCountUnread>0"
                                         :bg-left="-10*ss*xMult+'px'"
                                         :bg-top="-10*ss*xMult+'px'"
                                         :bg-min-width="25*ss*xMult+'px'"
                                         :font-size="40*ss*xMult"
                                         :line-height="40*ss*xMult"
                                         :bg-padding="10*ss*xMult+'px '+20*ss*xMult+'px'"
                                         :notifications-count="notificationsCountUnread"
                    />
                </div>
            </div>
        </div>
    </div>

    <LearnChips v-if="popupVisible.learnChips===true"/>
    <LearnNft v-if="popupVisible.learnNft===true"/>
    <LevelPopup v-if="popupLevelPopup===true"/>
    <RewardPopup v-if="popupRewardPopup.visible===true"/>
    <UserInfo v-if="pageVisibleUserInfo"/>
    <PopupLogin v-if="popupVisible.login===true"/>
    <PopupSignup v-if="popupVisible.signup===true"/>
    <Terms v-if="popupVisible.terms===true"/>
    <Privacy v-if="popupVisible.privacy===true"/>
    <LearnNewAccount v-if="popupVisible.learnNewAccount===true"/>
    <UserAccount v-if="popupVisible.userAccount===true"/>
    <ReferralInfo v-if="popupVisible.referralInfo===true"/>
    <ChipsClaim v-if="popupVisible.chipsClaim===true"/>
    <Tickets v-if="popupVisible.tickets===true"/>
    <BuyConfirm v-if="ticketsBuyCount.buyVisible===true"/>
    <Prizes v-if="prizes.visible===true"/>
    <Shop v-if="shop.visible===true"/>
    <ShopBuyConfirm v-if="popupBuyShopConfirm.visible===true" />
    <PopupOpenBot v-if="popupOpenBot.visible===true" />
    <PrizesClaim v-if="popupVisible.prizesClaim===true"/>
    <Notifications v-if="popupVisible.notifications===true"/>
    <WalletType v-if="popupWalletType.visible===true" />
    <PopupNoWallet v-if="popupNoWallet.visible===true" />
    <AchievStats v-if="popupVisible.achievStats===true" />
    <Rewards v-if="popupVisible.rewards===true" />
    <LuckyWheel v-if="popupLuckyWheel.visible===true" />

    <PopupFirst v-if="popupVisible.popupFirst===true && orient==='V'"/>
    <PopupAirdrop v-if="popupVisible.popupAirdrop===true" />
    <PopupConnectTelegram v-if="popupVisible.connectTelegram===true"/>
    <PopupConfirm v-if="popupConfirm.visible===true"/>
    <PopupMessage v-if="popupMessage.visible===true"/>
    <CookiePopup/>


</template>

<script>
    //export default defineComponent({              //Not working debugger in chrome
    import Backend from '../classes/backend.js';
    import Config from "../scripts/Config";
    import Utils from "../scripts/Utils";
    import {mapGetters} from "vuex";
    import AchievStats from "@/vue/account/AchievStats";
    import BuyConfirm from "@/vue/tickets/BuyConfirm";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import CookiePopup from "@/vue/elements/CookiePopup";
    import ChipsClaim from "@/vue/account/ChipsClaim";
    import LearnChips from "@/vue/pages/components/LearnChips";
    import LearnNft from "@/vue/pages/components/LearnNft";
    import LevelPopup from "@/vue/pages/components/LevelPopup";
    import menuBtnSvg from "@/assets/svg/menu_btn.svg";
    import Notifications from "@/vue/account/Notifications";
    import NotificationsNumber from "@/vue/elements/NotificationsNumber";
    import PopupConnectTelegram from "@/vue/elements/PopupConnectTelegram";
    import PopupMessage from "@/vue/elements/PopupMessage";
    import PopupLogin from "@/vue/account/PopupLogin";
    import PopupSignup from "@/vue/account/PopupSignup";
    import PopupNoWallet from "@/vue/shop/PopupNoWallet";
    import PopupOpenBot from "@/vue/shop/PopupOpenBot";
    import Privacy from "@/vue/pages/Privacy";
    import Prizes from "@/vue/tickets/Prizes";
    import PrizesClaim from "@/vue/tickets/PrizesClaim";
    import ReferralInfo from "@/vue/account/ReferralInfo";
    import RewardPopup from "@/vue/pages/components/RewardPopup";
    import Shop from "@/vue/shop/Shop";
    import ShopBuyConfirm from "@/vue/shop/ShopBuyConfirm";
    import Terms from "@/vue/pages/Terms";
    import Tickets from "@/vue/tickets/Tickets";
    import LearnNewAccount from "@/vue/pages/components/LearnNewAccount";
    import LuckyWheel from "@/vue/lucky/LuckyWheel";
    import UserAccount from "./account/UserAccount";
    import UserInfo from "./account/UserInfo";
    import WalletType from "@/vue/shop/WalletType";
    import PopupConfirm from "@/vue/elements/PopupConfirm";
    import PopupFirst from "@/vue/elements/PopupFirst";
    import PopupAirdrop from "@/vue/elements/PopupAirdrop";
    import Rewards from "@/vue/account/Rewards";

    export default {
        name: "Main",
        components: {Privacy, Terms, PopupSignup, PopupLogin, UserInfo, UserAccount, Tickets,BuyConfirm,PopupMessage,Prizes,PrizesClaim,Notifications,
            Shop,WalletType,PopupNoWallet,ShopBuyConfirm,CookiePopup,ButtonSvg,NotificationsNumber,ChipsClaim,LearnChips,LearnNft,ReferralInfo,
            AchievStats,LearnNewAccount,LuckyWheel,PopupOpenBot,PopupConnectTelegram,LevelPopup,PopupConfirm,PopupFirst,PopupAirdrop,Rewards,RewardPopup},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","pageVisibleUserInfo","popupVisible","ticketsBuyCount","popupMessage","prizes",
                "shop","popupWalletType","popupNoWallet","popupBuyShopConfirm","pageVisibleUserInfo","notificationsCountUnread","btnRad","xMult",
                "getLeft","popupLuckyWheel","popupOpenBot","popupLevelPopup","userLevel","popupConfirm","orient","gameType","getMenuRight","getMenuTop",
                "popupRewardPopup"]),
        },
        watch:{
            userLevel(newVal,oldVal){
                //MyLog('watch userLevel: '+newVal);
                if (oldVal!==null && newVal!==0 && oldVal!==100){
                    this.$store.state.popupLevelPopup=true;
                }
            },
            gameType(newVal){
            },
        },
        created() {
            this.getUserTries=0;
            MyLog("Start Main.created");
            const state=this.$store.state;

            Utils.getState();                    //Get game state from backend (Unlocked games, etc...)
            const hr=this.$route.href;
            let clearQuery=false;
            try{
                if (hr.includes('privacy')){
                    state.popupVisible.privacy=true;
                    clearQuery=true;
                }
                if (hr.includes('terms')){
                    state.popupVisible.terms=true;
                    clearQuery=true;
                }
            }catch (e) {}


            this.checkAuthorisation().then(()=>{                 //Set user info and and other data redirected (from google, face etc) or from local storage
                setTimeout(()=>{
                    this.$store.state.updateUserLevel();
                    let path = localStorage.getItem("path");
                    if (path) {
                        localStorage.removeItem("path");
                    }
                    else{
                        path=path?path:Config.defaultPath;                         //if no path than set 'home' by default
                    }

                    MyLog('Main.created push new path= '+path);
                    this.$router.push({path:path,query:clearQuery?null:this.$route.query});
                },1);
            });
        },
        mounted() {
            const state=this.$store.state;
            //state.showMessagePopup('123',state.messages.m029_5,true);
        },

        beforeUnmount() {

        },

        data: function () {
            return {
                menuBtnSvg:Utils.getGlobalLink(menuBtnSvg),
            }
        },
        methods:{
            async authDiscord(auth,loginData){
                let hs=this.$route.hash;
                const params = hs.split("&").map(part => part.replace(/#/, ""));
                const parsedParams = {};
                params.forEach(param => {
                    const parts = param.split("=");
                    parsedParams[parts[0]] = parts[1];
                });

                let accessToken, tokenType, stateStr;
                if (parsedParams.hasOwnProperty("access_token") && parsedParams.hasOwnProperty("token_type")) {
                    accessToken = parsedParams["access_token"];
                    tokenType = parsedParams["token_type"];
                    stateStr = parsedParams["state"];
                    let stateParams = Utils.processStateParam(stateStr);

                    if (loginData && loginData.loginMethod !== "discord" && loginData.loginMethod !== "guest") {
                        let self = this;
                        // Delay by 1 second to ensure that the connect discord api returns after get user api
                        setTimeout(function() {
                            self.connectDiscord({ accessToken:accessToken, tokenType:tokenType });
                        }, 1000);

                    }
                    else {
                        let loginObj={
                            loginMethod: auth,
                            accessToken,
                            tokenType,
                            referralCode: stateParams.referralCode,
                            jumpTask: stateParams.jumpTask,
                            loginData
                        };
                        let login = await Utils.loginBackend(loginObj);
                        if (login.error) {
                            this.openErrorPopup(login.error);
                        }
                    }
                }
            },
            btnMenuClick(){
                this.$store.commit("btnMenuClick");
            },
            async connectDiscord(connectData) {
                const loginData = JSON.parse(localStorage.getItem("loginData"));
                const connectDiscordResponse = await Backend.connectDiscord(loginData, connectData.accessToken, connectData.tokenType);

                if(!connectDiscordResponse.error) {
                    localStorage.setItem("loginData", JSON.stringify(connectDiscordResponse.loginData))

                    appVue.$store.commit("setLoginData", connectDiscordResponse.loginData);
                    appVue.$store.state.userId=connectDiscordResponse.loginData?connectDiscordResponse.loginData.userId:null;
                    MyLog("userId: "+appVue.$store.state.userId);
                    Utils.loginSetUserData(appVue.$store.state,connectDiscordResponse.user);
                }
            },
            async authFacebook(auth,loginData){
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                let code, ref;
                if (urlParams.get("code")) {
                    code = urlParams.get("code");
                    let stateStr = urlParams.get("state");
                    let stateParams = Utils.processStateParam(stateStr);
                    let loginObj = {
                        loginMethod: auth,
                        code,
                        redirectUri: "https://" + window.location.host + "/",
                        referralCode: stateParams.referralCode,
                        jumpTask: stateParams.jumpTask,
                        loginData
                    };
                    let login = await Utils.loginBackend(loginObj);
                    if(login.error) {
                        this.openErrorPopup(login.error);
                    }
                    try{
                        this.$route.query.code=undefined;
                        this.$route.query.state=undefined;
                    }
                    catch (e) {
                        console.log('Main.vue.authFacebook catch: ',e.message,'\nstack: ',e.stack)
                    }
                }
            },
            async authGoogle(auth){
                let hs=this.$route.hash;
                const params = hs.split("&").map(part => part.replace(/#/, ""));
                const parsedParams = {};
                params.forEach(param => {
                    const parts = param.split("=");
                    parsedParams[parts[0]] = parts[1];
                });

                let accessToken, tokenType, stateStr;
                let loginData = null;
                if (parsedParams.hasOwnProperty("access_token") && parsedParams.hasOwnProperty("token_type")) {
                    accessToken = parsedParams["access_token"];
                    tokenType = parsedParams["token_type"];
                    stateStr = parsedParams["state"];
                    let stateParams = Utils.processStateParam(stateStr);

                    let loginObj={
                        loginMethod: auth,
                        accessToken,
                        tokenType,
                        referralCode: stateParams.referralCode,
                        jumpTask: stateParams.jumpTask,
                        loginData
                    };
                    let login = await Utils.loginBackend(loginObj);
                    if(login.error) {
                        this.openErrorPopup(login.error);
                    }
                }
            },
            async authTelegram(tgInitData){
                const state=this.$store.state;
                let loginObj={
                    loginMethod: 'telegram',
                    accessToken:tgInitData,
                };
                MyLog('authTelegram loginObj:');
                MyLog(JSON.stringify(loginObj));
                let login = await Utils.loginBackend(loginObj);
                if(login.error) {
                    this.openErrorPopup(login.error);
                }
                else{
                    state.shop.currency='xtr';
                    state.tgInitData=tgInitData;
                    Backend.initTelegram({tgInitData:tgInitData, userId:state.userId});
                }

            },
            async checkAuthorisation() {
                //const authMethods = ["discord", "google", "facebook"];
                //MyLog(JSON.stringify(window.location));

                //if (Utils.getEnv()==='test')                //Check if website under telegram
                {
                    /*const urlParams = new URLSearchParams(window.location.search);
                    MyLog(urlParams);
                    const auth_data = Object.fromEntries(urlParams.entries());
                    MyLog(auth_data);//*/

                    if (window.Telegram){
                        const tg = window.Telegram.WebApp;
                        if (tg && tg.initData) {
                            MyLog('website was opened from telegram');
                            await this.authTelegram(tg.initData);
                            history.replaceState(null, null, null);
                            return;
                        }
                    }
                }
                let auth = localStorage.getItem("auth");
                localStorage.removeItem("auth");
                let loginData=localStorage.getItem("loginData");
                if (loginData){
                    loginData = JSON.parse(loginData);
                }
                if (!auth){             //if no auth then check for loginData in localStorage and try for login
                    if (loginData){
                        appVue.$store.commit("setLoginData", loginData);
                        appVue.$store.state.userId=loginData.userId;
                        await Utils.getUser(loginData);
                    }
                }
                if (auth==='facebook'){
                    await this.authFacebook(auth,loginData);
                }
                if (auth==='discord'){
                    await this.authDiscord(auth,loginData);
                }
                if (auth==='google'){
                    await this.authGoogle(auth);
                }
                history.replaceState(null, null, null);
            },

            openErrorPopup(err){
                const messages=this.$store.state.messages;
                const param=this.$store.state.popupMessage;
                param.msg=err;
                param.title=messages.m029_5;
                param.error=true;
                param.visible=true;
                MyLog("Main.openErrorPopup, err: "+err);
            },
        },
    }
    //);

</script>

<style lang="css" scoped>
    /*.resize-animation-stopper * {
        animation: none !important;
        transition: none !important;
    }*/
    .c-top_btn_menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }

    .c-main{
        display: flex;
        overflow: visible;
        position: absolute;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-main_bg{
        display: flex;
        overflow: visible;
        position: relative;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
</style>
