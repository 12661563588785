<template>
    <!--   Game messages popup for player "successful and error"   -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8));">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

            <div class="c-popup_first_2" :style="{'width': realWidth+'px','height': realWidth*1.77+'px','max-height': realHeight+'px'}">

                <img :src="imgFirst" alt=""
                     :style="{'width': '100%','height': 'auto'}"
                />

                <div class="c-popup_first_row" style="position: absolute;width: 100%;"
                     :style="{'bottom': 30*ss*xMult+'px','height': 50*ss*xMult+'px','gap': 10*ss*xMult+'px',}"
                >
                    <input type="checkbox" v-model="dontShow" />
                    <div class="c-popup_first_text"
                         :style="{'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                    >
                        {{messages.m054_08}}
                    </div>
                </div>

                <div class="c-popup_first_2" style="position: absolute;width: 100%;"
                     :style="{'bottom': 120*ss*xMult+'px','height': 110*ss*xMult+'px',}"
                >

                    <!--     Confirm button       -->
                    <ButtonSvgOval :callback="clickConfirm" :callback-wait="true"
                                   :btn-text="messages.m054_07"
                                   :bg-width="700*ss*xMult" :bg-height="100*ss*xMult"
                                   :max-width="700*ss*xMult"
                                   :font-size="45*ss*xMult"
                                   :svg-width="50*ss*xMult" :svg-height="50*ss*xMult"
                                   :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                   :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                   :click-scale="0.97"
                    />
                </div>


                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="72*ss*xMult"
                               :bg-height="72*ss*xMult"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import imgFirst from "@/assets/images/first_entrance.webp";

    export default defineComponent({
        name: 'PopupFirst',
        components: {ButtonSvg,ButtonSvgOval},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages",]),
        },
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),imgFirst:Utils.getGlobalLink(imgFirst),
                dontShow:false,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupVisible;
                param.popupFirst=false;

            },
            clickConfirm(){
                const param=this.$store.state.popupVisible;
                param.popupFirst=false;

                if (this.dontShow){
                    Utils.setShowWelcome(false);
                }
            },
        },
    });

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-popup_first_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }


    .c-popup_first_column{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_first_row{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_first_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        /*word-wrap: break-word;/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }


</style>
