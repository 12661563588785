<template>
    <div class="c-iframe"
         :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

        <iframe
                v-if="allGames[gameName]"
                id="mainframe"
                class="c-iframe_bg"
                :style="{'width': realWidth+'px','height': realHeight+'px',}"
                :src="gameUri"
        />

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GameList from "../../scripts/GameList";
    import Backend from '../../classes/backend.js';
    import Config from "../../scripts/Config";
    import ButtonSvg from "../elements/ButtonSvg";
    import menuBtnSvg from "@/assets/svg/menu_btn.svg";
    import Main from "../Main";
    import Utils from "@/scripts/Utils";

    export default {
        name: "PageSlot",
        components: {Main, ButtonSvg,},
        computed: {
            ...mapGetters(["ss","realWidth","realHeight","getLeft","btnRad","pageVisibleUserInfo",]),
            gameUri(){
                const gameUri = (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? 'https://test.blockspingaming.com/' : 'https://blockspingaming.com/';
                let uri='';
                uri=gameUri+GameList.allGames[this.gameName].uri;
                return uri;
            },
            gameName() {
                return this.$route.params.gameId.split("-").join("");
            },
        },
        created() {
            MyLog('game page created');
            window.addEventListener("message", this.receiveMessage, false);

            let path='/slot/'+this.gameName;
            localStorage.setItem("path",path);
        },
        mounted() {

        },
        beforeUnmount() {
            window.removeEventListener("message", this.receiveMessage);
        },

        data: function () {
            return {
                allGames: GameList.allGames,
                nextUser: {},
                menuBtnSvg:Utils.getGlobalLink(menuBtnSvg),
            }
        },
        methods:{
            async receiveMessage(message) {
                if (typeof message.data !== "string") {
                    return;
                }
                const data = JSON.parse(message.data);
                MyLog(data);
                switch (data.action) {
                    case "init": {
                        this.gameWindow = message.source;
                        if (this.$store.state.loginData) {
                            let initData = await Backend.init(
                                this.allGames[this.$route.params.gameId].name,
                                this.$store.state.loginData
                            );
                            if (initData.error) {
                                alert(initData.error);
                            } else {
                                initData["sound"] = this.$store.state.sound;
                                message.source.postMessage(JSON.stringify(initData), "*");
                                if (initData.balance) {
                                    this.$store.state.userData.chips=initData.balance;
                                }
                                if (initData.jackpot) {
                                    this.$store.state.blockspinState.jackpot=initData.jackpot;
                                }
                            }
                        } else {
                            this.initOnLogin = true;
                        }
                        break;
                    }
                    case "spin": {
                        if (!this.$store.getters.getIsGuestAndOutOfBets) {
                            const current = data.current;
                            const lines = current.lines;
                            const bet = current.bet;
                            const freespin = data.freespin;

                            if (!freespin) {
                                this.$store.state.userData.chips=this.$store.state.userData.chips - Math.round(bet * lines);
                            }

                            let spin = await Backend.spin(
                                this.allGames[this.$route.params.gameId].name,
                                this.$store.state.loginData,
                                lines,
                                bet
                            );
                            if (spin.error) {
                                alert(spin.error);
                            } else {
                                let spinData = spin.betData;
                                message.source.postMessage(JSON.stringify(spinData), "*");

                                spin.newBet.username = this.$store.getters.getUsername;
                                spin.newBet.avatar = this.$store.state.userData.avatar;

                                this.nextUser = spin.user;

                                this.$store.state.userData.gems=spinData.gems;
                                this.$store.state.blockspinState.jackpot=spinData.jackpot;
                                this.$store.commit("setNewBet", spin.newBet);

                                if (this.$store.getters.getIsGuest) {
                                    this.$store.state.userData.numBets += 1;
                                }
                                setTimeout(()=>{
                                    this.$store.state.updateUserLevel();
                                },1000);
                                setTimeout(()=>{
                                    this.$store.dispatch('rewardsUpdate');
                                },2000);
                            }
                        } else {
                            this.$store.state.userData.numBets += 1;
                        }
                        break;
                    }
                    case "bonus": {
                        const choice = data.choice;
                        let bonus = await Backend.bonus(
                            this.allGames[this.$route.params.gameId].name,
                            choice,
                            this.$store.state.loginData
                        );
                        if (bonus.error) {
                            alert(bonus.error);
                        } else {
                            let bonusData = bonus.bonusData;
                            message.source.postMessage(JSON.stringify(bonusData), "*");

                            bonus.newBet.username = this.$store.getters.getUsername;
                            bonus.newBet.avatar = this.$store.state.userData.avatar;

                            this.$store.commit("setPostSpinUserData", bonus.user);
                            this.$store.state.blockspinState.jackpot=bonusData.jackpot;
                            this.$store.commit("setNewBet", bonus.newBet);
                        }
                        break;
                    }
                    case "home": {
                        this.$router.push({path:Config.defaultPath,query:this.$route.query});
                        break;
                    }
                    case "soundOn": {
                        this.$store.commit("setSound", true);
                        break;
                    }
                    case "soundOff": {
                        this.$store.commit("setSound", false);
                        break;
                    }
                }
                if (data === 'reelsStopped' || (data && data.action && data.action === 'reelsStopped')) {
                    this.$store.commit("setPostSpinUserData", this.nextUser);
                }
            },
        },
    }

</script>

<style lang="css" scoped>


</style>
