<template>
    <div class="c-pool_games_bg unselectable"
         :style="{'width': realWidth-40*ss*xMult+'px','height': getHeight+'px',}">


        <div class="c-pool_games"
             :style="{'width': realWidth+'px','height': getHeight+'px',
                'gap': 5*ss*xMult+'px','padding-left':30*ss*xMult+'px','padding-right':30*ss*xMult+'px'}"

        >
            <!--  LEADERBOARD GAMES  -->
            <div class="c-pool_games_col" style="align-items: flex-start;position: relative;"
                 :style="{'width': 'auto','height': 160*ss*xMult+'px','margin-top':30*ss*xMult+'px',}"
            >
                <div class="c-pool_games_col c-pool_games_head_text"
                     :style="{'width': 'auto','height': 80*ss*xMult+'px', 'line-height': 80*ss*xMult+'px', 'font-size':70*ss*xMult+'px',}"
                >
                    {{messages.m043_01}}
                </div>

                <div class="c-pool_games_row" style="justify-content: space-between"
                     :style="{'width': '100%','height': 60*ss*xMult+'px',}"
                >
                    <!--  Resets in  -->
                    <div class="c-pool_games_col c-pool_games_head_text" style="opacity: 0.6;font-weight:500;"
                         :style="{'font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                    >{{messages.m042_02+' '+prizePoolGames.resetInValue}}</div>

                    <!--    See previous/current    -->
                    <div class="c-pool_games_bottom"
                         :style="{'width': 'auto','height': 50*ss*xMult+'px', 'gap': 0*ss*xMult+'px',}"
                    >
                        <div class="c-pool_games_bottom_text" style="text-decoration: underline;"
                             :style="{'font-size': 40*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                             @click="onClickCurrent()"
                        >{{getCurrentText}}</div>
                    </div>
                </div>
            </div>

            <!--  Table head  -->
            <div class="c-pool_games_head"
                 :style="{'width': (realWidth-80*ss*xMult)+'px','height': (orient==='V'?240:120)*ss*xMult+'px', 'gap': 10*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
            >
                <!--  Prize pool + chips prize  -->
                <div class="c-pool_games_head_2"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 110*ss*xMult+'px', 'margin-top': 0*ss*xMult+'px',}"
                >
                    <span class="c-pool_games_head_text" style="font-weight: 600;"
                          :style="{'width': 'auto','height': 80*ss*xMult+'px', 'line-height': 80*ss*xMult+'px', 'font-size':50*ss*xMult+'px',}"
                    >{{messages.m042_03}}
                    </span>

                    <!--  Period Leaderboard/Completed_games for horizontal -->
                    <div v-if="orient==='H'" class="c-pool_games_head_btns unselectable"
                         :style="{'width': (1000*ss*xMult).toString()+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',}"
                    >
                        <div class="c-pool_games_head_btn"
                             :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': prizePoolGames.prizePoolActive==='games'?btnBg:'none',
                            'box-shadow': prizePoolGames.prizePoolActive==='games'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                             @click="onClickBtn('games')"
                        >
                            <span class="c-pool_games_head_btn_txt"
                                  :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                {{messages.m043_02}}
                            </span>
                        </div>
                        <div class="c-pool_games_head_btn"
                             :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': prizePoolGames.prizePoolActive==='myProgress'?btnBg:'none',
                            'box-shadow': prizePoolGames.prizePoolActive==='myProgress'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                             @click="onClickBtn('myProgress')"
                        >
                            <span class="c-pool_games_head_btn_txt"
                                  :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                {{messages.m043_03}}
                            </span>
                        </div>
                    </div>

                    <span class="c-pool_games_head_chips" style="font-weight: 600;"
                          :key="getPrize"
                          :style="{'width': 'auto','height': 80*ss*xMult+'px', 'line-height': 80*ss*xMult+'px', 'font-size':50*ss*xMult+'px',}"

                    >{{getPrize}}</span>
                </div>

                <!--  Leaderboard/Completed_games  for vertical -->
                <div v-if="orient==='V'" class="c-pool_games_head_btns unselectable"
                     :style="{'width': (1000*ss*xMult).toString()+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',}"
                >
                    <div class="c-pool_games_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': prizePoolGames.prizePoolActive==='games'?btnBg:'none',
                            'box-shadow': prizePoolGames.prizePoolActive==='games'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                         @click="onClickBtn('games')"
                    >
                        <span class="c-pool_games_head_btn_txt"
                              :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m043_02}}
                        </span>
                    </div>
                    <div class="c-pool_games_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 100*ss*xMult+'px', 'border-radius': 60*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': prizePoolGames.prizePoolActive==='myProgress'?btnBg:'none',
                            'box-shadow': prizePoolGames.prizePoolActive==='myProgress'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                         @click="onClickBtn('myProgress')"
                    >
                        <span class="c-pool_games_head_btn_txt"
                              :style="{'width': 'auto','height': 60*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                        >
                            {{messages.m043_03}}
                        </span>
                    </div>
                </div>
            </div>

            <!--  Table with bets list prizePoolActive==='games' -->
            <div v-if="prizePoolGames.prizePoolActive==='games'" class="c-pool_games_table"
                 :style="{'width': (realWidth-80*ss*xMult).toString()+'px','height': realHeight-(orient==='V'?790:540)*ss*xMult+'px',
                  'gap': 5*ss*xMult+'px','margin-top':20*ss*xMult+'px',}"
            >
                <!--   USER/POINTS/PRIZE     -->
                <div class="c-pool_games_table_head"
                     :style="{'gap': 0*ss*xMult+'px','width': (realWidth-80*ss*xMult).toString()+'px','height': 80*ss*xMult+'px',}"
                >
                    <span class="c-pool_games_table_head_text" style="order: 0;"
                          :style="{'width': '60%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-left': 0*ss*xMult+'px'}"
                    >{{messages.m042_07}}</span>
                    <span v-if="getShowPoints"
                          class="c-pool_games_table_head_text" style="order: 1; text-align: -webkit-right;"
                          :style="{'width': '20%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-left': 0*ss*xMult+'px'}"
                    >{{messages.m042_08}}</span>
                    <span class="c-pool_games_table_head_text" style="order: 2; text-align: -webkit-right;"
                          :style="{'width': getShowPoints?'20%':'40%','height': 80*ss*xMult+'px',
                        'font-size': 45*ss*xMult+'px','line-height': 80*ss*xMult+'px','margin-right': 0*ss*xMult+'px'}"
                    >{{messages.m042_09}}</span>
                </div>

                <!-- scroll static with players bets-->
                <div class="c-pool_games_player"
                     :style="{'gap': 5*ss*xMult+'px','width': (realWidth-80*ss*xMult).toString()+'px','height': getScrollHeight+(getTokenAvailable?0:110)*ss*xMult+'px',
                        'max-height': getScrollHeight+(getTokenAvailable?0:110)*ss*xMult+'px',}"
                >

                    <!--   scroll content dynamic height PRIZES   -->
                    <div class="c-pool_games_scroll"
                         :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 20*ss*xMult+'px',
                         'padding-top': 0*ss*xMult+'px','padding-bottom': 0*ss*xMult+'px',}"
                    >
                        <PrizePoolPlayer
                                v-for="(item,index) in prizePoolObj[prizePoolGames.prizePoolActive].users"
                                :key="index"
                                :item="item"
                                :bg="getIsMeId(item.userId)===true?
                                    'hsla(0,0%,100%,.1)':
                                    (index % 2===0?'none':'none')"
                                :numb="(index+1).toString()"
                                :prize-pool-obj="prizePoolObj"
                        />
                        <PrizePoolPlayer v-if="!getIsMe"
                                         :key="11"
                                         :item="getMyItem"
                                         :bg="'hsla(0,0%,100%,.1)'"
                                         :numb="'-'"
                                         :prize-pool-obj="prizePoolObj"
                        />
                    </div>
                </div>

                <!-- Token prize -->
                <!-- v-if="getTokenAvailable" -->
                <div v-if="getTokenAvailable"
                     class="c-pool_games_head_btns unselectable"
                     :style="{'width': 730*ss*xMult+'px','height': 90*ss*xMult+'px', 'border-radius': 70*ss*xMult+'px','margin-top': 20*ss*xMult+'px',}"
                >
                    <div class="c-pool_games_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 90*ss*xMult+'px', 'border-radius': 70*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': prizePoolGames.prizePoolShowToken?'#FFE605':'none'}"
                         @click="onClickToken"
                    >
                        <span class="c-pool_games_head_btn_txt"
                              :style="{'width': 'auto','height': 90*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 90*ss*xMult+'px',
                                'color': prizePoolGames.prizePoolShowToken?'#1C1C1C':'#FFFFFF',}"
                        >
                            Token
                        </span>
                    </div>
                    <div class="c-pool_games_head_btn"
                         :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': 90*ss*xMult+'px', 'border-radius': 70*ss*xMult+'px',
                            'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                            'background': !prizePoolGames.prizePoolShowToken?'#FFE605':'none'}"
                         @click="onClickChips"
                    >
                        <span class="c-pool_games_head_btn_txt"
                              :style="{'width': 'auto','height': 90*ss*xMult+'px', 'font-size': 50*ss*xMult+'px','line-height': 90*ss*xMult+'px',
                                'color': !prizePoolGames.prizePoolShowToken?'#1C1C1C':'#FFFFFF',}"
                        >
                            Chips
                        </span>
                    </div>
                </div>

            </div>

            <!--  Table with bets list prizePoolActive==='myProgress' -->
            <div v-if="prizePoolGames.prizePoolActive==='myProgress'" class="c-pool_games_progress"
                 :style="{'width': realWidth-80*ss*xMult+'px','height': realHeight-(orient==='V'?790:540)*ss*xMult+'px',
                  'margin-top':20*ss*xMult+'px',}"
            >
                <div class="c-pool_games_progress_scroll"
                     :style="{'width': realWidth-80*ss*xMult+'px','height': 'auto','gap': 30*ss*xMult+'px',
                                'padding-top': 0*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}"
                >
                    <!--   You earn 1 point ....     -->
                    <div class="c-pool_games_head_btn_txt" style="font-weight: 500;white-space: pre-wrap;"
                         :style="{'width': realWidth-100*ss*xMult+'px','height': 'auto',
                            'font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}"
                    >
                        {{messages.m043_04}} <span style="color: #FFE500">{{messages.m043_05}}</span>
                        {{' '+messages.m043_06+' '+blockspinState.gamesLeaderboardBetsPerGame+' '+messages.m043_07+' ' +blockspinState.gamesLeaderboardMinimunBet+' '+messages.m043_08}}
                        {{'\n'+messages.m043_09}} <span style="color: #FFE500">{{messages.m043_10}}</span> {{messages.m043_11}}
                    </div>
                    <MyProgressGame
                            v-for="(item,index) in Object.keys(gamesList)"
                            :key="index"
                            :my-progress-obj="myProgressObj"
                            :game-name="item"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { defineComponent } from 'vue';
    import PrizePoolPlayer from "@/vue/pages/components/PrizePoolPlayer";
    import Utils from "@/scripts/Utils";
    import MyProgressGame from "@/vue/pages/components/MyProgressGame";
    import GameList from "@/scripts/GameList";

    export default defineComponent({
            //export default {
            name: "PrizePoolGames",
            components: {PrizePoolPlayer,MyProgressGame},
            computed: {
                ...mapGetters(["ss","realWidth","realHeight","messages","orient","xMult","prizePoolGames","blockspinState"]),
                prizePoolObj(){
                    const obj=this.prizePoolObjThis();
                    return obj;
                },
                myProgressObj(){
                    let obj={};
                    try {
                        obj=this.prizePoolObjThis().games.myProgress;
                    }catch (e) {}
                    return obj;
                },
                gamesList(){
                    return Utils.sortGames('allGames');
                },
                btnBg(){
                    return 'linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%)';
                },
                getHeight(){
                    const state=this.$store.state;
                    let h=state.realHeight-(state.orient==='V'?300:180)*state.ss*this.$store.getters.xMult;
                    return h;
                },
                getScrollHeight(){
                    const state=this.$store.state;
                    let h=state.realHeight-(state.orient==='V'?1010:740)*state.ss*this.$store.getters.xMult;
                    return h;
                },
                getMyItem(){
                    let obj = {
                        username: this.$store.state.userData.discordUsername?this.$store.state.userData.discordUsername:this.$store.state.userId,
                        avatar: this.$store.state.userIcon,
                        points: this.prizePoolObj[this.$store.state.prizePoolGames.prizePoolActive].myPoints,
                        prize: this.prizePoolObj[this.$store.state.prizePoolGames.prizePoolActive].myPrize,
                    }
                    return obj;
                },
                getPrize(){
                    const state=this.$store.state;
                    let pr=0;
                    let s='0 CHIPS'
                    const prizePoolActive='games';
                    try {
                        let prizePool = this.prizePoolObj[prizePoolActive];

                        if (prizePool && prizePool.tokenPrizePool && prizePool.tokenPrizePool.amount>0 && this.$store.state.prizePoolGames.prizePoolShowToken){
                            pr = Number(prizePool.tokenPrizePool.amount);
                            s = pr ? pr.toString() + ' ' + prizePool.tokenPrizePool.tokenSymbol : 0 + ' ' + prizePool.tokenPrizePool.tokenSymbol;
                        }
                        else {
                            const bs=state.blockspinState;
                            pr=bs.gamesLeaderboardPrize;
                            s = pr ? pr.toLocaleString() + ' CHIPS' : 0 + ' CHIPS';
                        }
                    }catch (e) {
                        console.log('PrizePoolGames.getPrize catch: ',e.message,'\nstack: ',e.stack)
                    }
                    return s;
                },
                getIsMe(){
                    let b=false;
                    try {
                        let users = this.prizePoolObj[this.$store.state.prizePoolGames.prizePoolActive].users;
                        for (let i=0;i<users.length;i++) {
                            if (users[i].userId===this.$store.state.userId) return true;
                        }
                    }catch (e) {

                    }
                    return b;
                },
                getCurrentText(){
                    //values 'current'/'previous'
                    return this.$store.state.prizePoolGames.prizePoolSelect==='current'?'See previous':'See current';
                },

                getTokenAvailable(){
                    try {
                        let prizePool = this.prizePoolObj[this.$store.state.prizePoolGames.prizePoolActive];
                        if (prizePool && prizePool.tokenPrizePool && prizePool.tokenPrizePool.amount > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }catch (e) {
                        console.log('PrizePoolGames.getTokenAvailable catch: ',e.message,'\nstack: ',e.stack)
                        return false;
                    }
                },

                getShowPoints(){        //If prizePoolGames.prizePoolShowToken true and TokenAvailable in current prizePool then don't show points
                    try {
                        let prizePool = this.prizePoolObj[this.$store.state.prizePoolGames.prizePoolActive];
                        if (prizePool && prizePool.tokenPrizePool && prizePool.tokenPrizePool.amount > 0 && this.$store.state.prizePoolGames.prizePoolShowToken) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    catch (e) {
                        console.log('PrizePoolGames.getShowPoints catch: ',e.message,'\nstack: ',e.stack)
                        return true;
                    }
                },//*/
            },
            data: function () {
                return {
                    resetInInterval:null,
                }
            },
            created() {

            },
            mounted() {
                this.startResetInInterval();
            },
            beforeUnmount() {
                this.stopResetInInterval();
            },
            methods:{
                onClickBtn(btn) {       //'games', 'myProgress', 'weekly' prize pool displayed
                    if (this.$store.state.prizePoolGames.prizePoolActive!==btn);
                    else return;
                    this.$store.state.prizePoolGames.prizePoolActive=btn;
                },
                getIsMeId(id){
                    let b=false;
                    if (id===this.$store.state.userId) return true;
                    return b;
                },
                onClickCurrent() {
                    this.$store.state.prizePoolGames.prizePoolSelect=this.$store.state.prizePoolGames.prizePoolSelect==='current'?'previous':'current';

                },
                onClickToken() {
                    this.$store.state.prizePoolGames.prizePoolShowToken=true;
                },
                onClickChips() {
                    this.$store.state.prizePoolGames.prizePoolShowToken=false;
                },
                prizePoolObjThis(){
                    const state=this.$store.state;
                    //const s=this.$store.getters.gameTypeLeaderboard;            //slot/scratch/crash/instant
                    const s='slot';                                             //Leaderboard Games only for slots
                    const obj=state.leaderboard[s][state.prizePoolGames.prizePoolSelect];    //current/previous

                    return obj;
                },

                startResetInInterval(){
                    this.resetInInterval=setInterval(()=>{
                        appVue.$store.state.prizePoolGames.resetInValue=Utils.getResetIn('weekly');
                    },200);
                },
                stopResetInInterval(){
                    if (this.resetInInterval){
                        clearInterval(this.resetInInterval);
                        this.resetInInterval=null;
                    }
                },

            },
        }
    );

</script>

<style lang="css" scoped>

    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-pool_games_bg{
        display: flex;
        overflow: -moz-scrollbars-none;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-pool_games{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: radial-gradient(178.9% 78.61% at 47.02% 0%, rgb(0, 172, 145) 0%, rgb(0, 83, 190) 100%);
    }

    .c-pool_games_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-pool_games_row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .c-pool_games_head{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }
    .c-pool_games_head_2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-pool_games_head_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        white-space: nowrap;
    }
    .c-pool_games_head_chips{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: bold;

        letter-spacing: -0.03em;
        text-transform: uppercase;
        /*background: linear-gradient(281.23deg, #00B2FF 37.33%, #80D9FF 68.64%);*/
        background: rgb(243, 228, 25);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /*background-clip: text;*/
        text-fill-color: transparent;
        flex: none;
        flex-grow: 0;
    }
    .c-pool_games_head_btns{
        display: flex;
        flex-direction: row;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .c-pool_games_head_btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: none;
        overflow: hidden;
        flex-grow: 1;
    }
    .c-pool_games_head_btn_txt{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        text-align: -webkit-center;
        letter-spacing: -0.03em;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: white;
    }

    .c-pool_games_table{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 0px;
        flex: none;
        flex-grow: 0;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }
    .c-pool_games_table_head{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: none;
        order: 0;
        flex-grow: 0;

        /*background: rgba(0, 0, 0, 0.4);/**/
    }
    .c-pool_games_table_head_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 1);
        flex: none;
        flex-grow: 0;
    }

    .c-pool_games_bottom{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }
    .c-pool_games_bottom_text{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        flex: none;
        flex-grow: 0;
        word-wrap: break-word;
        white-space: nowrap;
    }
    .c-pool_games_player{               /* Тело чата */
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: none;
        overflow: hidden;
        font-family: 'Rubik';

        /*background: rgba(255, 255, 255, 0.2);/**/
    }

    .c-pool_games_scroll{
        display: block;
        /*flex-wrap: wrap;/**/
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

    .c-pool_games_progress{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        flex: none;
        flex-grow: 0;
        overflow: hidden;

        /*background: rgba(255, 255, 255, 0.2);/**/
    }
    .c-pool_games_progress_scroll{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
    }


</style>
