<template>
    <!--     Shop card       -->
    <div class="c-shop_elem unselectable"
         :style="{'width': 480*ss*xMult+'px','height': (smallCard?810:740)*ss*xMult+'px',}"
    >
        <div class="c-shop_elem_bg"
             :style="{'width': 440*ss*xMult+'px','height': (smallCard?600:630)*ss*xMult+'px','margin-bottom': 0*ss*xMult+'px',
                'border-radius': 60*ss*xMult+'px','gap': (smallCard?0:30)*ss*xMult+'px',
                'border': !this.getItemDiscount()?6*ss*xMult+'px solid #233C64':6*ss*xMult+'px solid #dc8400',
                'background':!this.getItemDiscount()?'radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%)':'radial-gradient(73.33% 57.49% at 50% 50%,#ffc83c 0,#dc8400 100%)'}"
        >
            <img v-if="this.getItemDiscount()" :src="saleImg" class="c-shop_elem_img" alt=""
                 :style="{'width': '100%','height': '100%','top': 0*ss*xMult+'px','left': 0*ss*xMult+'px',}"
            />
            <!--     Image only for VIP       -->
            <img v-if="!smallCard" :src="Utils.getGlobalLink(getItem.img)" class="c-shop_elem_img_2" alt=""
                 :style="{'width': 400*ss*xMult+'px','height': 233*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}"
            />

            <!--     Card title and Info btn       -->
            <div class="c-shop_elem_row" style="justify-content: center;"
                 :style="{'width': 440*ss*xMult+'px','height': (itemKey!=='unlockStats' && itemKey!=='xpFreeze' && itemKey!=='xpRecovery'?120:270)*ss*xMult+'px','margin-bottom': 10*ss*xMult+'px',}">
                <div class="c-shop_elem_text"
                     :style="{'width': 350*ss*xMult+'px','height': 100*ss*xMult+'px','font-size': 40*ss*xMult+'px','line-height': 50*ss*xMult+'px',}">
                    {{getItem.name}}
                </div>
                <!--   I button   -->
                <ButtonSvgOval v-if="itemType!=='chips'"
                               :svg-normal="infoSvg"
                               :bg-width="80*ss*xMult" :bg-height="75*ss*xMult"
                               :max-width="50*ss*xMult"
                               :svg-width="40*ss*xMult" :svg-height="40*ss*xMult"
                               :background-val="'hsla(0,0%,100%,0.2)'"
                               :border-val="'none'"
                               :callback="btnInfoClick" :callback-wait="true"
                               :click-scale="0.9"
                />
            </div>

            <!--     Minus/BuyCount/Plus       -->
            <div v-if="itemKey!=='unlockStats' && itemKey!=='xpFreeze' && itemKey!=='xpRecovery' && itemType!=='vip'" class="c-shop_elem_row" style="background: rgba(0, 0, 0, 0.3);"
                 :style="{'width': 400*ss*xMult+'px','height': 120*ss*xMult+'px','margin-bottom': 30*ss*xMult+'px',
                    'border-radius': 200*ss*xMult+'px',}">
                <!--     Minus       -->
                <ButtonSvgOval
                        :callback="clickMinus" :callback-wait="false"
                        :btn-text="'-'"
                        :bg-width="110*ss*xMult" :bg-height="100*ss*xMult"
                        :font-size="60*ss*xMult"
                        :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                        :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                        :border-radius="200*ss*xMult"
                        :click-scale="0.9"
                />

                <div class="c-shop_elem_text"
                     :style="{'width': 150*ss*xMult+'px','height': 120*ss*xMult+'px','font-size': 60*ss*xMult+'px','line-height': 120*ss*xMult+'px',}">
                    {{buyCount}}
                </div>
                <!--     Plus       -->
                <ButtonSvgOval
                        :callback="clickPlus" :callback-wait="false"
                        :btn-text="'+'"
                        :bg-width="110*ss*xMult" :bg-height="100*ss*xMult"
                        :font-size="60*ss*xMult"
                        :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(93.92deg,#00c6fb .27%,#005bea 105.74%) var(--x,0)/200%'"
                        :borderVal="'linear-gradient(102.76deg,#0053be 3.6%,#79e0ff 53.89%,#0053be 99.19%)'"
                        :border-radius="200*ss*xMult"
                        :click-scale="0.9"
                />
            </div>

            <!--     Buy button       -->
            <div class="c-shop_elem_col"
                 :style="{'width': 440*ss*xMult+'px','height': 108*ss*xMult+'px','margin-bottom': 40*ss*xMult+'px',}">
                <ButtonSvgOval :svg-normal="getCurrencyImage"
                               :callback="clickBuy" :callback-wait="true"
                               :btn-text="getPrice"
                               :btn-full-text="this.getItemDiscount()?getFullPrice:null"
                               :bg-width="420*ss*xMult" :bg-height="100*ss*xMult"
                               :max-width="420*ss*xMult"
                               :font-size="(this.getItemDiscount()?38:45)*ss*xMult"
                               :svg-width="(this.getItemDiscount()?50:60)*ss*xMult" :svg-height="(this.getItemDiscount()?50:60)*ss*xMult"
                               :backgroundVal="getBg"
                               :borderVal="getBorder"
                               :click-scale="0.95"
                />
            </div>
        </div>

        <!--     Sale region       -->
        <div class="c-shop_elem_bg"
             :style="{'width': 440*ss*xMult+'px','height': 90*ss*xMult+'px','margin-bottom': 0*ss*xMult+'px',}"
        >
            <img v-if="this.getItemDiscount()" :src="saleSvg" class="c-shop_elem_img" alt=""
                 :style="{'width': 'auto','height': 75*ss*xMult+'px','top': 0*ss*xMult+'px',}"
            />
            <div v-if="this.getItemDiscount()" class="c-shop_elem_img c-shop_elem_text_2"
                 :style="{'width': 440*ss*xMult+'px','height': 75*ss*xMult+'px','top': 0*ss*xMult+'px',
                    'font-size': 40*ss*xMult+'px','line-height': 75*ss*xMult+'px',}"
            >
                {{messages.m032_06+' -'+this.getItemDiscount()+'%'}}
            </div>
        </div>

        <img v-if="smallCard" :src="Utils.getGlobalLink(getItem.img)" class="c-shop_elem_img" alt=""
             :style="{'width': 'auto','height': 300*ss*xMult+'px','top': itemType==='boost'?-30*ss*xMult+'px':0*ss*xMult+'px',}"
        />
        <!--     Quick Help       -->
        <div v-if="getItem.quickHelp===true" class="c-shop_elem_img"
             :style="{'bottom': 420*ss*xMult+'px','left': 25*ss*xMult+'px',}"
        >
            <PopupInfo     :bg-width="435*ss*xMult" :bg-height="0"
                           :border-radius="30*ss*xMult"
                           :font-size="35*ss*xMult"
                           :btn-text="getItem.description"
                           :background-val="'rgba(255, 255, 255, 0.1)'"
                           :border-val="'1px solid rgba(255, 255, 255, 0.5)'"
                           :callback="popupInfoClick"
            />
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import waxSvg from "@/assets/svg/chains/icon_wax_white.svg";
    import solSvg from "@/assets/svg/chains/icon_sol.svg";
    import roninSvg from "@/assets/svg/chains/icon_ron.svg";
    import dollarSvg from "@/assets/svg/chains/icon_dollar.svg";
    import saleImg from "@/assets/images/digitalPrizes/img_card_rays.webp";
    import imgStarTg from "@/assets/images/star_tg.webp";
    import * as Constants from "@/classes/Constants";
    import saleSvg from "@/assets/svg/shop/sale.svg";
    import infoSvg from "@/assets/svg/icon_info";
    import PopupInfo from "@/vue/elements/PopupInfo";
    import imgTon from "@/assets/svg/chains/icon_ton";
    import imgPol from "@/assets/svg/chains/icon_polygon";
    import imgBnb from "@/assets/svg/chains/icon_bnb";

    export default defineComponent({
        name: 'ShopElement',
        components: {ButtonSvgOval,PopupInfo},
        computed: {
            ...mapGetters(["ss","xMult","blockspinState","messages"]),
            getItem(){
                const state=this.$store.state;
                let item=state.shop.items[this.itemType][this.itemKey];
                item=item?item:{};
                return item;
            },
            smallCard(){
                if (this.itemType==='chips' || this.itemType==='boost')
                    return true;
                return false;
            },

            getCurrencyImage(){
                const currency=this.$store.state.shop.currency;
                let img='';
                img=currency==='wax'?Utils.getGlobalLink(waxSvg):img;
                img=currency==='sol'?Utils.getGlobalLink(solSvg):img;
                img=currency==='ronin'?Utils.getGlobalLink(roninSvg):img;
                img=currency==='usd'?Utils.getGlobalLink(dollarSvg):img;
                img=currency==='xtr'?Utils.getGlobalLink(imgStarTg):img;
                img=currency==='pol'?Utils.getGlobalLink(imgPol):img;
                img=currency==='ton'?Utils.getGlobalLink(imgTon):img;
                return img;
            },
            getPrice(){
                const shop=this.$store.state.shop;
                const currency=this.$store.state.shop.currency;
                const item=this.getThisItem();
                let txt=item.price[shop.currency];
                if (this.getItemDiscount()){
                    txt=txt*(100-this.getItemDiscount())/100;
                    if (currency==='xtr'){
                        txt=Math.round(txt);
                    }
                    else{
                        txt=Constants.priceFraction[currency] ? txt.toFixed(Constants.priceFraction[currency]) : txt;
                    }
                }
                txt=txt?txt.toString():'0';
                return txt;
            },

            getFullPrice(){             //if sale get full item price
                const shop=this.$store.state.shop;
                const item=this.getThisItem();
                let txt=item.price[shop.currency];

                txt=txt?txt.toString():'0';
                return txt;
            },

            getBg(){
                const state=this.$store.state;
                let txt='linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(95.19deg,#0cff7c -16.14%,#00af90 137.96%) var(--x,0)/200%';
                return txt;
            },
            getBorder(){
                const state=this.$store.state;
                let txt='linear-gradient(102.76deg,#06a071 3.6%,#bcffde 53.89%,#06a071 99.19%)';
                return txt;
            },
            buyCount(){
                const shop=this.$store.state.shop;
                let val=shop.items[this.itemType][this.itemKey].amount;
                val=val?val:1;
                return val;
            },
        },
        data(){
            return {
                Utils:Utils,saleImg:Utils.getGlobalLink(saleImg),saleSvg:Utils.getGlobalLink(saleSvg),
                infoSvg:Utils.getGlobalLink(infoSvg),popupQuickHelp:this.quickHelp,
                imgTon:Utils.getGlobalLink(imgTon), imgPol:Utils.getGlobalLink(imgPol),imgBnb:Utils.getGlobalLink(imgBnb),
            };
        },
        created() {
            this.quickHelp=false;
            //console.log(this.clickScale)
        },
        mounted() {
        },
        methods: {
            clickMinus() {
                const item=this.getThisItem();
                item.amount=item.amount>1?item.amount-1:1;
            },
            clickPlus() {
                const item=this.getThisItem();
                item.amount=item.amount<99?item.amount+1:99;
            },
            clickBuy() {        //{itemType,itemKey,currency,amount,price}  //price for 1 item
                const item=this.getThisItem();
                const shop=this.$store.state.shop;
                let obj={
                    itemType:this.itemType,
                    itemKey:this.itemType==='chips'?Number(this.itemKey):this.itemKey,
                    currency:shop.currency,
                    amount:item.amount,
                    price:this.getThisPrice(),
                };
                this.$store.dispatch('clickBuy',obj);

            },
            getItemDiscount(){          //Return max value of item discount (from shop or member card)
                let discount=0;
                const state=this.$store.state;
                try{
                    const item=state.shop.items[this.itemType][this.itemKey];
                    if (item){
                        discount=item.discount?item.discount:0;
                        if (this.itemType==='chips') {
                            const userMc = this.$store.getters.getUserMonthlyCard;
                            let memberDiscount = userMc ? userMc.chipsDiscountPercentage : 0;
                            discount=Math.max(discount,memberDiscount);
                        }
                    }
                }catch (e) {}
                return discount;
            },
            getThisItem(){
                const state=this.$store.state;
                let item=state.shop.items[this.itemType][this.itemKey];
                item=item?item:{};
                return item;
            },
            getThisPrice(){
                const shop=this.$store.state.shop;
                const currency=this.$store.state.shop.currency;
                const item=this.getThisItem();
                let txt=item.price[shop.currency];
                if (this.getItemDiscount()){
                    txt=txt*(100-this.getItemDiscount())/100;
                    if (currency==='xtr'){
                        txt=Math.round(txt);
                    }
                    else{
                        txt=Constants.priceFraction[currency] ? txt.toFixed(Constants.priceFraction[currency]) : txt;
                    }
                }
                txt=Number(txt);
                return txt;
            },
            btnInfoClick(){
                const item=this.getThisItem();
                if (this.itemType==='boost'){
                    item.quickHelp=true;
                    setTimeout(()=>{
                        item.quickHelp=false;
                    },6000)
                }
                if (this.itemType==='vip'){
                    const param=this.$store.state.popupVip;
                    param.visible=true;
                    param.vipType=this.itemKey;
                }

            },
            popupInfoClick(){
                const item=this.getThisItem();
                item.quickHelp=false;
            }
        },

        props:{
            itemType: {       // {chips/boost/vip}
                type: String,
                default: null
            },
            itemKey: {       //item name in Constants
                type: String,
                default: null
            },
            currency: {       //{wax/sol/ronin/usd}
                type: String,
                default: null
            },
        }
    });
</script>

<style lang="css" scoped>

    .c-shop_elem{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_elem_bg{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;

        /*background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%);/**/
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_elem_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_elem_row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-shop_elem_img{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-shop_elem_img_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-shop_elem_text{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-center;
    }

    .c-shop_elem_text_2{
        color: #FFFFFF;

        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        text-align: -webkit-center;
    }

</style>
