import './style.css';
import './fonts.css';
import { createSSRApp,createApp } from "vue";
import AppVue from "./vue/AppVue";
import { store } from "./vue/store";
import router from './vue/router';
import Utils from "./scripts/Utils";
import Config from "./scripts/Config";
//import { TweenMax } from "gsap";
import gsap from "gsap";
//import { renderToString } from 'vue/server-renderer'
import DynamicFonts from "./classes/DynamicFonts";
const dynamicFonts=new DynamicFonts();

//import { Buffer } from 'buffer';
//window.Buffer = Buffer;

if ((document.location.hostname==='127.0.0.1')||(document.location.hostname==='localhost')){
    Config.isLocal=true;
}
window.Utils=Utils;
Utils.setRouterCorrPath();
window.TweenMax=gsap;
window.MyLog=Utils.MyLog;

//Will mount the vue app inside a HTML element which id equals to "app" (div into templetes/index.html file)
window.appVue=createApp(AppVue);
MyLog('createApp(AppVue)')
window.appVue.use(router)
MyLog('appVue.use(router)')
window.appVue.use(store);
MyLog('appVue.use(store)')
window.appVue.$store=store;

let interval=0;
function mountVue(){
    window.appVue.mount('#app');
    MyLog('appVue.mount(\'#app\')')
    const state=window.appVue.$store.state;
    //renderToString(window.appVue).then((html) => {})
    Utils.init();                       //Set resize event, init variables

    if (Config.isLocal) {
        //state.tgMode=true;
        //setTimeout(()=>{state.popupRewardPopup.visible=true;},4000);
        //state.popupVisible.popupAirdrop=true;
    }
    if (Utils.getEnv()==='test'){

    }
}

if (document.getElementById('app')) {
    mountVue();
}
else {
    interval=setInterval(()=>{
        if (document.getElementById('app')) {
            clearInterval(interval);
            mountVue();
        }
    },100);
}

/*

try{
    if (window.Telegram && window.Telegram.WebApp){
        window.Telegram.WebApp.ready();
        const tonProvider = window.TonWalletProvider //|| require('ton-wallet-sdk');
        let ii=1;
    }
}
catch (e) {
    console.log('window.Telegram catch: ',e.message,'\nstack: ',e.stack)
}

try {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(Utils.getGlobalLink('firebase-messaging-sw.js'))
            .then((registration) => {
                MyLog('Service Worker registered successfully:' + registration);
            })
            .catch((error) => {
                MyLog('Service Worker registration failed:' + error);
            });
    }
}
catch (e) {
    MyLog('Service Worker registration catch.')
}//*/


//let i=Utils.isMobile();
//test
