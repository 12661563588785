<template>
    <!--   Airdrop popup
    background: var(--Gradients-Blue, linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%));

/* Modal-670/Blue */
box-shadow: 0px 0px 100px 0px rgba(255, 255, 255, 0.30) inset, 0px 0px 60px 0px rgba(25, 201, 243, 0.40);
    -->
    <div class="c-components_body unselectable" style="backdrop-filter: blur(5px);background: linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

            <div class="c-popup_airdrop_2" :style="{'width': realWidth+'px','height': realHeight+'px',}">
                <!--   Bg image   -->
                <img :src="orient==='V'?imgAirMob:imgAirHoriz" alt="" style="position: absolute;bottom: 0;"
                     :style="{'width': (orient==='V'?imgWidthVert:imgWidthHoriz)+'px','height': (orient==='V'?imgHeightVert:imgHeightHoriz)+'px'}"
                />

                <div class="c-app_csc" style="position: absolute;left: 0;top:0;"
                     :style="{'width': realWidth+'px','height': realHeight+'px',}">

                    <!--   Tittle + message   -->
                    <div class="c-popup_airdrop_2"
                         :style="{'width': realWidth-100*ss*xMult+'px','height': realHeight-(orient==='V'?475:345)*ss*xMult+'px','margin-top': 140*ss*xMult+'px',}"
                    >
                        <!--   Tittle   -->
                        <div class="c-popup_airdrop_text"
                             :style="{'width': '100%','height': (orient==='V'?195:130)*ss*xMult+'px',
                        'font-size': 55*ss*xMult+'px','line-height': 65*ss*xMult+'px',}"
                        >
                            <div class="c-popup_airdrop_text" style="flex-direction: row"
                                 :style="{'width': '100%','height': 80*ss*xMult+'px','gap': 20*ss*xMult+'px',
                                'font-size': 55*ss*xMult+'px','line-height': 80*ss*xMult+'px',}"
                            >
                                {{messages.m_air_tittle_1}}
                                <img :src="imgSpinfunC" alt=""
                                     :style="{'height': 80*ss*xMult+'px','height': 80*ss*xMult+'px',}"
                                />
                            </div>
                            {{messages.m_air_tittle_2}}
                        </div>

                        <!--   Message   -->
                        <div class="c-popup_airdrop_static"
                             :style="{'width': '100%','height': realHeight-(orient==='V'?620:430)*ss*xMult+'px',}"
                        >
                            <div class="c-popup_airdrop_text_scroll"
                                 :style="{'width': '100%','height': 'auto',
                            'font-size': 50*ss*xMult+'px','line-height': 60*ss*xMult+'px',}"
                            >
                                {{messages.m_air_text}}
                            </div>
                        </div>

                    </div>

                    <!--     Soon button       -->
                    <div class="c-popup_airdrop_2" style="position: absolute;width: 100%;"
                         :style="{'bottom': (orient==='V'?180:50)*ss*xMult+'px','height': 110*ss*xMult+'px',}"
                    >
                        <ButtonSvgOval :callback="clickConfirm" :callback-wait="true"
                                       :btn-text="messages.m045_09"
                                       :svg-normal="imgSpinfun"
                                       :bg-width="400*ss*xMult" :bg-height="100*ss*xMult"
                                       :max-width="400*ss*xMult"
                                       :font-size="45*ss*xMult"
                                       :svg-width="70*ss*xMult" :svg-height="70*ss*xMult"
                                       :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                                       :borderVal="'linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%)'"
                                       :click-scale="0.97"
                        />
                    </div>


                    <!--   X button   -->
                    <div style="position: absolute"
                         :style="{'top': 40*ss*xMult+'px','right': 40*ss*xMult+'px', 'width': 72*ss*xMult+'px','height': 72*ss*xMult+'px',}"
                    >
                        <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                                   :bg-width="72*ss*xMult"
                                   :bg-height="72*ss*xMult"
                                   :callback="btn_xClick" :callback-wait="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import Utils from "@/scripts/Utils";
    import ButtonSvg from "@/vue/elements/ButtonSvg";
    import ButtonSvgOval from "@/vue/elements/ButtonSvgOval";

    import btn_xSvg from "@/assets/svg/btn_x.svg";
    import imgAirMob from "@/assets/images/airdrop/air_bg_mob.webp";
    import imgAirHoriz from "@/assets/images/airdrop/air_bg_horiz.webp";
    import imgSpinfun from "@/assets/svg/tickets/spinfun.svg";
    import imgSpinfunC from "@/assets/images/airdrop/spinfun.webp";

    export default defineComponent({
        name: 'PopupAirdrop',
        components: {ButtonSvg,ButtonSvgOval},
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","orient"]),
            imgWidthVert(){
                const state=this.$store.state
                let width=state.realWidth;
                if (state.realWidth/state.realHeight<1024/1820){
                    width=state.realHeight*1024/1820;
                }
                return width
            },
            imgHeightVert(){
                const state=this.$store.state
                let height=state.realHeight;
                if (state.realWidth/state.realHeight>=1024/1820){
                    height=state.realWidth/(1024/1820);
                }
                return height;
            },
            imgWidthHoriz(){
                const state=this.$store.state
                let width=state.realWidth;
                return width
            },
            imgHeightHoriz(){
                const state=this.$store.state
                let height=state.realWidth*(2150/2680);
                return height;
            },
        },
        data(){
            return {
                btn_xSvg:Utils.getGlobalLink(btn_xSvg),imgAirMob:Utils.getGlobalLink(imgAirMob),
                imgSpinfun:Utils.getGlobalLink(imgSpinfun),imgAirHoriz:Utils.getGlobalLink(imgAirHoriz),
                imgSpinfunC:Utils.getGlobalLink(imgSpinfunC),
                dontShow:false,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        mounted() {
        },
        methods: {
            btn_xClick(){
                const param=this.$store.state.popupVisible;
                param.popupAirdrop=false;
            },
            clickConfirm(){
                const param=this.$store.state.popupVisible;
                param.popupAirdrop=false;
            },
        },
    });

</script>

<style lang="css" scoped>
    ::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }

    .c-popup_airdrop_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-popup_airdrop_text{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;

        /*word-wrap: break-word;
        white-space: pre-wrap;/**/
    }

    .c-popup_airdrop_static {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .c-popup_airdrop_text_scroll{
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        color: #FFFFFF;
        text-align: -webkit-left;

        overflow-x: hidden;
        overflow-y: auto;

        word-wrap: break-word;/**/
        white-space: pre-wrap;
        /*background: rgba(0, 0, 0, 0.3);/**/
    }

</style>
