<template>
    <div class="unselectable">
        <link rel="preload" fetchpriority="high" as="image" :href="iconFirst" type="image/webp">
        <link rel="preload" fetchpriority="high" as="image" :href="iconKingarthur" type="image/webp">
        <link rel="preload" fetchpriority="high" as="image" :href="iconTigerland" type="image/webp">
        <link rel="preload" fetchpriority="high" as="image" :href="iconCartel" type="image/webp">
        <link rel="preload" fetchpriority="high" as="image" :href="iconNight" type="image/webp">
        <link rel="preload" fetchpriority="high" as="image" :href="iconLegends" type="image/webp">

        <!--BgFrame/-->
        <DeviceScreen/>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from "vuex";
    import BgFrame from "./BgFrame";
    import DeviceScreen from "./DeviceScreen";
    import Config from "../scripts/Config";

    import iconFirst from "@/assets/images/allGames/goldofgods.webp";
    import iconKingarthur from "../assets/images/allGames/kingarthur.webp";
    import iconTigerland from "../assets/images/allGames/tigerland.webp";
    import iconCartel from "../assets/images/allGames/cartel.webp";
    import iconNight from "../assets/images/allGames/night.webp";
    import iconLegends from "../assets/images/allGames/legends.webp";

    //export default defineComponent({              //Not working debugger in chrome
    export default {
        name: "Home",
        components: {DeviceScreen, BgFrame},
        computed: {
            ...mapGetters([]),
        },
        created() {
            MyLog("/home create started")
        },
        data: function () {
            return {
                iconFirst:iconFirst,iconKingarthur:iconKingarthur,iconTigerland:iconTigerland,iconCartel:iconCartel, iconNight:iconNight,
                iconLegends:iconLegends,
            }
        },
        methods:{

        },
    }
    //);

</script>

<style lang="css" scoped>

</style>
